<template>
  <div class="content-block dx-card responsive-paddings">
    <form action="post-action" @submit.prevent="handleSubmit">
      <div class="input-group">
        <DxSelectBox
          class="group"
          :data-source="docTypeOptions"
          label="Pilih Tipe Dokumen"
          v-model="documents.document_type"
          :is-required="true"
          :read-only="readOnly"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxSelectBox
          class="group"
          :data-source="unitBisnisOptions"
          label="Pilih Unit Bisnis"
          value-expr="id"
          display-expr="name"
          v-model="documents.company_id"
          :is-required="true"
          @valueChanged="changeUnitBisnis"
          :read-only="readOnly"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxSelectBox
          class="group"
          :data-source="departmentOptions"
          label="Pilih Department"
          value-expr="department_id"
          display-expr="department_name"
          v-model="documents.department_id"
          :is-required="true"
          :read-only="readOnlyDept || readOnly"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>
      </div>

      <DxTextBox
        class="custom-form"
        label="Nama Dokumen"
        v-model:value="documents.document_name"
        :is-required="true"
        ><DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxTextBox>

      <DxTextArea
        class="custom-form"
        label="Ruang Lingkup"
        :height="90"
        :input-attr="{ 'aria-label': 'Notes' }"
        v-model:value="documents.document_scope"
      >
        <DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxTextArea>

      <DxTagBox
        class="custom-form"
        :search-enabled="true"
        :data-source="relatedDepartments"
        label="Pilih Department Relevan"
        display-expr="department_name"
        value-expr="department_id"
        :value="valueRelated"
        @value-changed="onValueChanged"
        :show-selection-controls="true"
        :maxFilterQueryLength="9999"
        item-template="item"
      >
        <template #item="{ data: item }">
          <div class="custom-item">
            <div class="product-name">
              {{ item.department_name }} <b>|| {{ item.company_name }}</b>
            </div>
          </div>
        </template>
        <DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxTagBox>

      <DxButton
        v-if="!loading"
        text="Simpan Data"
        type="success"
        icon="save"
        :use-submit-behavior="true"
      />

      <DxLoadIndicator v-else />
    </form>
  </div>
</template>
  
  <script>
import DxButton from "devextreme-vue/button";
import DxSelectBox from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import DxTextArea from "devextreme-vue/text-area";
import DxTagBox from "devextreme-vue/tag-box";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  components: {
    DxButton,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxTextArea,
    DxTagBox,
    DxLoadIndicator,
  },
  data() {
    return {
      paramsRoute: this.$route.params,
      formRef: "form-add-document",
      docTypeOptions: ["KETENTUAN PERUSAHAAN", "SOP", "WI", "FORM"],
      unitBisnisOptions: "",
      departmentOptions: "",
      readOnlyDept: true,
      sectionOptions: "",
      buttonOptions: {
        text: "Register",
        type: "success",
        useSubmitBehavior: true,
      },
      documents: {},
      loading: false,
      valueRelated: [],
      relatedDepartments: {},
      readOnly: false,
      docNameOld: "",
    };
  },
  computed: {
    ...mapGetters("document", ["getDocument", "getDocumentStatus"]),
    ...mapGetters("requestDocument", ["getRequestDocument"]),
    ...mapGetters("department", ["getAllDepartment"]),
    ...mapGetters("unitBisnis", ["getAllUnitBisnis"]),
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      this.readOnly = true;
      if (this.getDocument) {
        this.documents = this.getDocument;
        this.setValueTagRelated(this.getDocument.related_department);
      } else {
        this.loadDocumentList(this.paramsRoute.id);
      }
    } else if (
      this.paramsRoute.condition == "buat-dokumen-baru" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      this.readOnly = true;
      if (this.getRequestDocument) {
        console.log(this.getRequestDocument, "BUAT DOKUMEN BARU");
        const depart = this.getDepartment(
          this.getRequestDocument.department_id
        );

        this.documents.document_type = this.getRequestDocument.doc_type;
        this.documents.company_id = depart.company_id;
        this.documents.department_id = this.getRequestDocument.department_id;
        this.documents.document_name = this.getRequestDocument.doc_name;
        this.documents.request_document = this.getRequestDocument;
      } else {
        this.loadRequestDocument(this.paramsRoute.id);
      }
    }
    this.unitBisnisOptions = this.getAllUnitBisnis;
    this.relatedDepartments = this.getAllDepartment;
    // this.unitBisnis();
    // this.getRelatedDepartments();
    // if (this.getDocumentStatus == "Edit") {
    //   this.dataDocument();
    // }
    if (this.getDocumentStatus == "Redirect") {
      this.loading = true;
      this.getRequestDoc();
    }
  },

  emits: ["get-document"],
  methods: {
    setValueTagRelated(params) {
      if (params) {
        this.valueRelated = params.split(",");
      } else {
        this.valueRelated = [];
      }
    },
    getDepartment(params) {
      const depart = this.getAllDepartment?.find(
        (e) => e.department_id == params
      );
      return depart;
    },
    async loadRequestDocument(params) {
      try {
        const getReqDoc = await this.$store.dispatch(
          "requestDocument/getRequestDocuments",
          `/${params}`
        );
        const depart = this.getDepartment(getReqDoc.data.department_id);
        this.documents.document_type = getReqDoc.data.doc_type;
        this.documents.company_id = depart.company_id;
        this.documents.department_id = getReqDoc.data.department_id;
        this.documents.document_name = getReqDoc.data.doc_name;

        this.documents.request_document = getReqDoc.data;
        // this.documents = getReqDoc.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    async loadDocumentList(params) {
      try {
        const getDoc = await this.$store.dispatch(
          "document/getDocuments",
          `/${params}`
        );
        this.setValueTagRelated(getDoc.data.related_department);
        this.documents = getDoc.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    async getDocumentByName() {
      const doc = await this.$store.dispatch(
        "document/getDocuments",
        `?document_name=${this.documents.document_name}`
      );
      return doc.data;
    },
    // async getRelatedDepartments() {
    //   this.relatedDepartments = this.getAllDepartment;
    // },
    onValueChanged(e) {
      let related = "";
      related = e.value;
      console.log(related.join(), "arrr");

      this.documents.related_department = related.join();
    },
    async changeUnitBisnis(params) {
      // const text = `?company=${params.value}`;
      // const depart = await this.$store.dispatch(
      //   "department/getDepartments",
      //   text
      // );
      const depart = this.getAllDepartment?.filter(
        (e) => e.company_id == params.value
      );
      if (this.paramsRoute.condition == "add") {
        this.documents.department_id = "";
      }
      this.readOnlyDept = false;
      this.readOnlyDocNum = true;
      this.departmentOptions = depart;
    },
    // async unitBisnis() {
    //   const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
    //   this.unitBisnisOptions = units.data;
    // },
    async dataDocument() {
      let results = this.getDocument;
      this.documents = results;
      this.docNameOld = results.document_name;
      this.readOnly = true;
      if (this.documents.related_department != null) {
        this.valueRelated = this.documents.related_department.split(",");
      }
    },
    async getRequestDoc() {
      let results = this.getRequestDocument;

      const depart = this.getAllDepartment.find(
        (res) => res.department_id == results.department_id
      );

      this.documents.document_name = results.doc_name;
      this.docNameOld = results.doc_name;
      this.documents.document_type = results.doc_type;
      this.documents.company_id = depart.company_id;
      this.documents.department_id = results.department_id;

      this.readOnly = true;
      this.loading = false;
    },
    checkRelated(params) {
      const checkRelated = this.documents.related_department.includes(params);
      if (!checkRelated) {
        this.documents.related_department = `${this.documents.related_department},${params}`;
      }
    },
    async handleSubmit() {
      this.loading = true;
      this.checkRelated(this.documents.department_id);
      if (this.docNameOld) {
        if (this.docNameOld == this.documents.document_name) {
          this.loading = true;
          this.$emit("get-document", this.documents);
          return;
        }
      }
      // const doc = await this.getDocumentByName();
      // if (doc.length > 0) {
      //   notify("Nama dokumen sudah ada,Silahkan buat baru", "warning", 2000);
      //   return;
      // }
      this.$emit("get-document", this.documents);
    },
  },
};
</script>
  <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.group {
  display: inline-block;
}
.input-group {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Auto-fit columns with a minimum width */
  gap: 10px; /* Optional: space between input elements */
  margin-bottom: 25px;
  margin-top: 10px;
}

.input-group .input-field {
  width: 100%;
}
</style>