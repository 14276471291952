import axios from 'axios';
import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            document: null,
            documentStatus: null,
            searchDocument: "",
        }
    },
    mutations: {
        setDocument(state, payload) {
            state.document = payload
        },
        setDocumentStatus(state, payload) {
            state.documentStatus = payload
        },
        cleanDocument(state, payload) {
            state.document = null
        },
        setSearchDocument(state, payload) {
            state.searchDocument = payload
        },
    },
    actions: {
        setDocument({ commit }, params) {
            commit('setDocument', params)
        },
        setDocumentStatus({ commit }, params) {
            commit('setDocumentStatus', params)
        },
        cleanDocument({ commit }) {
            commit('cleanDocument')
        },
        setSearchDocument({ commit }, params) {
            commit('setSearchDocument', params)
        },
        async getDocument({ commit, state }, params) {
            return await Api()
                .get(`documents/${params.id}`).then((res) => {
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async getDocuments({ commit, state }, params) {
            return await Api()
                .get(`documents${params}`).then((res) => {
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async getWithDocApproval({ commit, state }, params) {
            return await Api()
                .get(`documents/verif-document${params}`).then((res) => {
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async getDocumentAccess({ commit, state }, params) {
            return await Api()
                .get(`documents/document-access${params}`).then((res) => {
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async getDocumentByDocType({ commit, state }, params) {
            console.log(params);
            return await Api()
                .get(`documents/doc-type${params}`).then((res) => {
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async getRequestApprove({ }, params) {
            console.log(params);
            return await Api()
                .get(`request-documents/approve?page=${params.page}&limit=${params.perPage}&sorted_by=${params.sortedBy}&sort=${params.sort}&search=${params.filterValue}&applicant_id=${params.applicant_id}`).then((res) => {
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async getRequestHistory({ }, params) {
            return await Api()
                .get(`request-documents/history?page=${params.page}&limit=${params.perPage}&sorted_by=${params.sortedBy}&sort=${params.sort}&search=${params.filterValue}&applicant_id=${params.applicant_id}`).then((res) => {
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async postDocument({ }, params) {
            const now = new Date();
            return await Api()
                .post("documents", params,);
        },
        async updateDocument({ }, params) {
            return await Api()
                .put(`documents/${params.id}`, params,);
        },
        async destroyDocument({ }, params) {
            return await Api()
                .delete(`documents/${params.id}`, params,);
        },
    },
    getters: {
        getDocument(state) {
            return state.document;
        },
        getDocumentStatus(state) {
            return state.documentStatus;
        },
        getSearchDocument(state) {
            return state.searchDocument;
        },
    }
}