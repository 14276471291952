<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addPicChecklist" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in picChecklist" :key="index">
          <DxSelectBox
            class="custom-form"
            :data-source="activityOption"
            label="Pilih Lingkup Aktivitas"
            value-expr="id"
            display-expr="activity"
            :is-required="true"
            v-model="form.activity_id"
            :search-enabled="true"
            item-template="item"
            :read-only="readOnly"
          >
            <template #item="{ data: item }">
              <div class="custom-item">
                <div class="product-name">
                  <b>{{ item.activity }}</b>
                  <span> / List Proses : {{ item.process.process_name }}</span>
                  <span>
                    / Clausul :
                    {{ item.process.checklist.clausul_relevan }} ({{
                      departmentName({
                        value: item.process.checklist.department_id,
                      })
                    }})</span
                  >
                </div>
              </div>
            </template>
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>

          <DxTextBox
            class="custom-form"
            label="PIC"
            v-model:value="form.pic"
            :is-required="true"
            ><DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextBox>

          <DxButton
            v-if="picChecklist.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading" class="custom-form">
          <DxButton
            v-if="!paramId && picChecklist.length < 10"
            class="customBtn"
            text="Form"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            class="customBtn"
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else class="custom-form" />
      </form>
    </div>
  </div>
</template>
              
    <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      picChecklist: [
        {
          activity_id: "",
          pic: "",
        },
      ],
      readOnly: false,
      unitBisnisOptions: "",
      departmentOptions: "",
      readOnlyDept: true,
      paramId: this.$route.params.id,
      activityOption: {},
    };
  },
  computed: {
    ...mapGetters("picChecklist", ["getPicChecklist", "getPicChecklistStatus"]),
    ...mapGetters("scopeActivities", ["getScopeActivities"]),
    ...mapGetters("department", ["getAllDepartment"]),
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      this.readOnly = true;
      if (this.getPicChecklist) {
        this.picChecklist[0] = this.getPicChecklist;
      } else {
        this.loadPic(this.paramId);
      }
    }
    this.getActivity();
  },
  props: {},
  emits: ["get-pic-checklist"],
  methods: {
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "-";
      }
      return `${depart.company_name} | ${depart.department_name}`;
    },
    async loadPic(params) {
      try {
        const getPic = await this.$store.dispatch(
          "picChecklist/getPicChecklist",
          `/${params}`
        );

        this.picChecklist[0] = getPic.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.picChecklist.push({
        activity_id: "",
        pic: "",
      });
    },
    removeForm(index) {
      this.picChecklist.splice(index, 1);
    },
    async getActivity() {
      const text = `?limit=999999`;
      const getActivity = await this.$store.dispatch(
        "scopeActivities/getScopeActivities",
        text
      );
      this.activityOption = getActivity.data;
    },
    async handleSubmit(e) {
      this.loading = true;
      this.$emit("get-pic-checklist", this.picChecklist);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
              
              <style scoped>
.customBtn {
  margin: 1%;
}
.custom-form {
  margin-top: 10px;
}
.dx-list-item-content {
  white-space: normal;
}
</style>