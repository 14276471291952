<template>
  <div>
    <h2 class="content-block">Daftar Template Dokumen</h2>
    <tableTemplateDocs :role="'suAdmin'"></tableTemplateDocs>
  </div>
</template>
              
        <script>
import tableTemplateDocs from "@/components/documentManagement/tableTemplateDocs.vue";

export default {
  data() {
    return {};
  },
  mounted() {},
  props: {},
  methods: {},
  components: {
    tableTemplateDocs,
  },
};
</script>