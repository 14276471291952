<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addAuditChecklist" @submit.prevent="handleSubmit">
        <DxSelectBox
          class="custom-form"
          :data-source="unitBisnisOptions"
          label="Pilih Unit Bisnis"
          value-expr="id"
          display-expr="name"
          :is-required="true"
          @valueChanged="changeUnitBisnis"
          v-model="valueCompany"
          :read-only="readOnlyCom"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>
        <DxSelectBox
          class="custom-form"
          :data-source="departmentOptions"
          label="Pilih Department"
          value-expr="department_id"
          display-expr="department_name"
          v-model="valueDepartment"
          :is-required="true"
          :read-only="readOnlyDept"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>
        <div v-for="(form, index) in auditChecklist" :key="index">
          <div v-if="auditChecklist.length > 1 && index > 0">
            <DxSelectBox
              class="custom-form"
              :data-source="unitBisnisOptions"
              label="Pilih Unit Bisnis"
              value-expr="id"
              display-expr="name"
              :is-required="true"
              v-model="valueCompany"
              :read-only="true"
            >
            </DxSelectBox>

            <DxSelectBox
              class="custom-form"
              :data-source="departmentOptions"
              label="Pilih Department"
              value-expr="department_id"
              display-expr="department_name"
              v-model="valueDepartment"
              :read-only="true"
            >
            </DxSelectBox>
          </div>
          <!-- <DxTextBox
            v-if="auditChecklist.length > 1 && index > 0"
            class="custom-form"
            label="Unit Bisnis *"
            v-model:value="valueCompany"
            :read-only="true"
          >
          </DxTextBox>
          <DxTextBox
            v-if="auditChecklist.length > 1 && index > 0"
            class="custom-form"
            label="Department *"
            v-model:value="valueDepartment"
            :read-only="true"
          >
          </DxTextBox> -->
          <DxTextArea
            class="custom-form"
            label="Clausul Relevan"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="form.clausul_relevan"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextArea>
          <DxButton
            v-if="auditChecklist.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading" class="custom-form">
          <DxButton
            v-if="!paramId && auditChecklist.length < 10"
            class="customBtn"
            text="Form"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            class="customBtn"
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else class="custom-form" />
      </form>
    </div>
  </div>
</template>
          
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      auditChecklist: [
        {
          company_id: "",
          department_id: "",
          clausul_relevan: "",
        },
      ],
      valueCompany: "",
      valueDepartment: "",
      readOnly: false,
      unitBisnisOptions: "",
      departmentOptions: "",
      readOnlyCom: false,
      readOnlyDept: true,
      paramId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("auditChecklist", ["getAuditChecklist"]),
    ...mapGetters("auditPlan", ["getAuditPlan"]),
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      this.readOnlyCom = true;
      this.readOnlyDept = true;
      if (this.getAuditChecklist) {
        this.auditChecklist[0] = this.getAuditChecklist;
        this.valueCompany = this.getAuditChecklist.company_id;
        this.valueDepartment = this.getAuditChecklist.department_id;
      } else {
        this.loadAuditChecklist(this.paramId);
      }
    }
    this.unitBisnis();
  },
  emits: ["get-audit-checklist"],
  methods: {
    async loadAuditChecklist(params) {
      try {
        const getChecklist = await this.$store.dispatch(
          "auditChecklist/getAuditChecklist",
          `/${params}`
        );

        this.auditChecklist[0] = getChecklist.data;
        this.valueCompany = getChecklist.data.company_id;
        this.valueDepartment = getChecklist.data.department_id;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.auditChecklist.push({
        clausul_relevan: "",
      });
    },
    removeForm(index) {
      this.auditChecklist.splice(index, 1);
    },
    async changeUnitBisnis(params) {
      const text = `?company=${params.value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );

      if (!this.paramId && this.paramId.trim() == "") {
        this.readOnlyDept = false;
      }
      this.departmentOptions = depart.data;
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
      this.unitBisnisOptions = units.data;
    },
    async handleSubmit(e) {
      this.loading = true;
      this.$emit("get-audit-checklist", {
        checklist: this.auditChecklist,
        company: this.valueCompany,
        department: this.valueDepartment,
      });
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
          
          <style scoped>
.customBtn {
  margin: 1%;
}
.custom-form {
  margin-top: 10px;
}
</style>