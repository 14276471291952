<template>
  <div>
    <h2 class="content-block">Audit Checklist {{ title }}</h2>
    <filter-table-unit-bisnis
      @get-unit-bisnis="getCompany"
      @get-department="getDepartment"
    ></filter-table-unit-bisnis>

    <div class="input-group content-block" v-if="permissions.canCreate">
      <div class="dx-card">
        <div class="card-header">
          <b>Action</b>
        </div>
        <div class="card-body">
          <DxButtonx
            v-for="btn in btnAction"
            :key="btn.text"
            class="customBtn"
            :text="btn.text"
            icon="add"
            type="default"
            @click="actionAdd(btn.link)"
          />
        </div>
      </div>
    </div>

    <br />
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <DxSearchPanel :visible="true" />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          hint="edit"
          icon="fa fa-pen-to-square"
          @click="edit"
          v-if="permissions.canUpdate"
        />
        <DxButton
          hint="hapus"
          icon="fa fa-trash"
          @click="popupDestroy"
          v-if="permissions.canDelete"
        />
      </dx-column>
      <dx-column data-field="clausul_relevan" caption="Clausul Relevan" />
      <dx-column
        data-field="department_id"
        caption="Department"
        :customize-text="departmentName"
      />

      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{ data: auditChecklist }">
        <tableProcess
          :roles="roles"
          :auditChecklist="auditChecklist.data"
          :ProcessProps="auditChecklist.data.audit_process"
          :permissionProps="permissions"
        ></tableProcess>
      </template>
    </dx-data-grid>
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
      <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";
import tableProcess from "@/components/audit/tableProcess.vue";

import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    DxButtonx,
    tableProcess,
    FilterTableUnitBisnis,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            search: filterValue,
            company: vthis.company || "",
            department: vthis.department || "",
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "audit-checklist-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      btnAdd: null,
      popupDelete: false,
      deleteData: {},
      company: "",
      department: "",
      title: "",
      btnAction: [
        {
          text: "Clausul",
          link: "/audit-checklist/form",
        },
        {
          text: "Proses",
          link: "/audit-process/form",
        },
        {
          text: "Lingkup Aktivitas",
          link: "/scope-activities/form",
        },
        {
          text: "PIC",
          link: "/pic-checklist/form",
        },
        {
          text: "Potensi Risiko",
          link: "/potential-risk/form",
        },
        {
          text: "Pengendalian Risiko",
          link: "/audit-risk-control/form",
        },
        {
          text: "Item Verifikasi",
          link: "/audit-verification-item/form",
        },
      ],
      permissions: {
        canCreate: null,
        canUpdate: null,
        canDelete: null,
        canVerified: null,
        unfilteredData: null,
      },
    };
  },
  created() {
    const permission = this.$route.meta.permission;
    // check permission
    this.permissions.canCreate = (permission & 2) > 0;
    this.permissions.canUpdate = (permission & 4) > 0;
    this.permissions.canVerified = (permission & 8) > 0;
    this.permissions.canDelete = (permission & 16) > 0;
    this.permissions.unfilteredData = (permission & 32) > 0;
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    ...mapGetters("department", ["getAllDepartment"]),
  },
  props: {
    roles: {
      type: String,
    },
  },
  methods: {
    actionAdd(params) {
      router.push(params);
    },
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "-";
      }
      return `${depart.company_name} | ${depart.department_name}`;
    },
    getCompany(value) {
      this.company = value;
      this.dataGrid.refresh();
    },
    getDepartment(value) {
      this.department = value;
      if (value) {
        const depart = this.getAllDepartment;
        const departName = depart.find(
          (depart) => depart.department_id == value
        );
        this.title = departName.department_name;
      } else {
        this.title = "";
      }
      this.dataGrid.refresh();
    },
    confirmDestroy() {
      this.$store
        .dispatch("auditChecklist/destroyAuditChecklist", this.deleteData)
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;

      this.deleteData = params.row.data;
    },
    edit(params) {
      router.push(`/audit-checklist/form/${params.row.key}`);
    },
    onToolbarPreparing(e) {
      const vThis = this;

      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "auditChecklist/getAuditChecklist",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
};
</script>
      <style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
/*.customBtn {
  margin: 1%;
} */

/* Menata bagian header (judul) */
.card-header {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}

/* Menata tombol-tombol di dalam card */
.card-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  padding: 10px;
}

.customBtn {
  min-width: 50px;
  margin: 5px;
}

/* Responsif untuk tampilan layar kecil */
@media (max-width: 768px) {
  .customBtn {
    min-width: 100px;
  }
}

/* Responsif untuk tampilan layar sangat kecil */
@media (max-width: 480px) {
  .customBtn {
    min-width: 90px;
  }

  .card-body {
    flex-direction: column;
    align-items: center;
  }

  .customBtn {
    margin-bottom: 10px;
  }
}
</style>