<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addAuditFindingType" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in auditFindingType" :key="index">
          <DxTextBox
            class="custom-form"
            label="Nama Jenis Temuan"
            v-model:value="form.name"
            :is-required="true"
            ><DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextBox>

          <DxTextArea
            class="custom-form"
            label="Deskripsi"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="form.desc"
          >
          </DxTextArea>
          <DxButton
            v-if="auditFindingType.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading" class="custom-form">
          <DxButton
            v-if="!paramId && auditFindingType.length < 10"
            class="customBtn"
            text="Form"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            class="customBtn"
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else class="custom-form" />
      </form>
    </div>
  </div>
</template>
          
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      auditFindingType: [
        {
          name: "",
          desc: "",
        },
      ],
      readOnly: false,
      paramId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("findingType", ["getFindingType", "getFindingTypeStatus"]),
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      if (this.getAuditRiskControl) {
        this.auditFindingType[0] = this.getFindingType;
      } else {
        this.loadAuditFindingType(this.paramId);
      }
    }
  },
  emits: ["get-audit-finding-type"],
  methods: {
    async loadAuditFindingType(params) {
      try {
        const getType = await this.$store.dispatch(
          "findingType/getFindingType",
          `/${params}`
        );

        this.auditFindingType[0] = getType.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.auditFindingType.push({
        name: "",
        desc: "",
      });
    },
    removeForm(index) {
      this.auditFindingType.splice(index, 1);
    },
    async handleSubmit(e) {
      this.loading = true;
      this.$emit("get-audit-finding-type", this.auditFindingType);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
          
          <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.customBtn {
  margin: 1%;
}
</style>