<template>
  <div>
    <span class="content-block title-detail">Related Department :</span>
    <div class="content-block dx-card table-center">
      <DxDataGrid
        :data-source="departments"
        :show-borders="true"
        :column-auto-width="true"
        key-expr="department_id"
        :remote-operations="true"
        :ref="gridRef"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :show-info="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />

        <dx-column data-field="company_name" caption="Unit Bisnis" />
        <dx-column data-field="department_name" caption="Department" />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxPaging,
} from "devextreme-vue/data-grid";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
  },
  props: ["templateData"],
  data() {
    return {
      pageSizes: [5, 10, 15],
      gridRef: "related-table",
      department_id: this.related,
      departments: [],
      only: false,
    };
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    related() {
      if (!this.templateData.related_department) {
        return null;
      }
      return this.templateData.related_department.split(",");
    },
    ...mapGetters("department", ["getAllDepartment"]),
  },
  mounted() {
    this.getRelatedDepartment();
  },
  methods: {
    getRelatedDepartment() {
      const depart = this.getAllDepartment;
      const filteredData = depart.filter((item) =>
        this.related.includes(item.department_id)
      );
      this.departments = filteredData;
    },
  },
};
</script>

<style scoped>
.table-center {
  width: 60%;
}
.title-detail {
  text-align: start;
  font-weight: bold;
}
</style>