<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addAuditRiskControl" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in auditRiskControl" :key="index">
          <DxSelectBox
            class="custom-form"
            :data-source="potentialOption"
            label="Pilih Potensi Risiko"
            value-expr="id"
            display-expr="potential"
            :is-required="true"
            v-model="form.potential_id"
            :search-enabled="true"
            item-template="item"
            :read-only="readOnly"
          >
            <template #item="{ data: item }">
              <div class="custom-item">
                <div class="product-name">
                  <b>{{ item.potential }}</b>
                  <span> / PIC : {{ item.pic_checklist.pic }}</span>
                  <span>
                    / Lingkup Aktivitas :
                    {{ item.pic_checklist.activity.activity }}</span
                  >
                  <span>
                    / Proses :
                    {{ item.pic_checklist.activity.process.process_name }}</span
                  >
                  <span>
                    / Clausul :
                    {{
                      item.pic_checklist.activity.process.checklist
                        .clausul_relevan
                    }}
                    ({{
                      departmentName({
                        value:
                          item.pic_checklist.activity.process.checklist
                            .department_id,
                      })
                    }})</span
                  >
                </div>
              </div>
            </template>
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>

          <DxTextArea
            class="custom-form"
            label="Pengendalian Risiko"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="form.name"
          >
          </DxTextArea>
          <DxTextBox
            class="custom-form"
            label="Tipe"
            v-model:value="form.type"
            :is-required="true"
            ><DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextBox>
          <DxButton
            v-if="auditRiskControl.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading" class="custom-form">
          <DxButton
            v-if="!paramId && auditRiskControl.length < 10"
            class="customBtn"
            text="Form"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            class="customBtn"
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else class="custom-form" />
      </form>
    </div>
  </div>
</template>
            
  <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      auditRiskControl: [
        {
          potential_id: "",
          name: "",
          type: "",
        },
      ],
      readOnly: false,
      verificationStatusOptions: [
        { name: "OK" },
        { name: "NC" },
        { name: "Obs" },
      ],
      potentialOption: {},
      paramId: this.$route.params.id,
      readOnly: false,
    };
  },
  computed: {
    ...mapGetters("auditRiskControl", [
      "getAuditRiskControl",
      "getAuditRiskControlStatus",
    ]),
    ...mapGetters("potentialRisk", ["getPotentialRisk"]),
    ...mapGetters("department", ["getAllDepartment"]),
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      this.readOnly = true;
      if (this.getAuditRiskControl) {
        this.auditRiskControl[0] = this.getAuditRiskControl;
      } else {
        this.loadPic(this.paramId);
      }
    }
    this.getPotential();
  },
  props: {},
  emits: ["get-audit-risk-control"],
  methods: {
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "-";
      }
      return `${depart.company_name} | ${depart.department_name}`;
    },
    async loadPic(params) {
      try {
        const getRiskControl = await this.$store.dispatch(
          "auditRiskControl/getAuditRiskControl",
          `/${params}`
        );

        this.auditRiskControl[0] = getRiskControl.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.auditRiskControl.push({
        potential_id: "",
        name: "",
        type: "",
      });
    },
    removeForm(index) {
      this.auditRiskControl.splice(index, 1);
    },
    async getPotential() {
      const text = `?limit=999999`;
      const getPotential = await this.$store.dispatch(
        "potentialRisk/getPotentialRisk",
        text
      );
      this.potentialOption = getPotential.data;
    },
    async handleSubmit(e) {
      this.loading = true;
      this.$emit("get-audit-risk-control", this.auditRiskControl);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
            
            <style scoped>
.customBtn {
  margin: 1%;
}
.custom-form {
  margin-top: 10px;
}
.dx-list-item-content {
  white-space: normal;
}
</style>