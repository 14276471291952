<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addAuditFaultType" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in auditFaultType" :key="index">
          <DxTextBox
            class="custom-form"
            label="Nama Tipe Kesalahan"
            v-model:value="form.name"
            :is-required="true"
            ><DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextBox>

          <DxTextArea
            class="custom-form"
            label="Deskripsi"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="form.desc"
          >
          </DxTextArea>
          <DxButton
            v-if="auditFaultType.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading" class="custom-form">
          <DxButton
            v-if="!paramId && auditFaultType.length < 10"
            class="customBtn"
            text="Form"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            class="customBtn"
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else class="custom-form" />
      </form>
    </div>
  </div>
</template>
          
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      auditFaultType: [
        {
          name: "",
          desc: "",
        },
      ],
      readOnly: false,
      paramId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("faultType", ["getFaultType", "getFaultTypeStatus"]),
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      if (this.getFaultType) {
        this.auditFaultType[0] = this.getFaultType;
      } else {
        this.loadFaultType(this.paramId);
      }
    }
  },
  props: {
    roles: {
      type: String,
    },
  },
  emits: ["get-audit-fault-type"],
  methods: {
    async loadFaultType(params) {
      try {
        const getFactor = await this.$store.dispatch(
          "faultType/getFaultType",
          `/${params}`
        );

        this.auditFaultType[0] = getFactor.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.auditFaultType.push({
        name: "",
        desc: "",
      });
    },
    removeForm(index) {
      this.auditFaultType.splice(index, 1);
    },
    async handleSubmit(e) {
      this.loading = true;
      this.$emit("get-audit-fault-type", this.auditFaultType);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
          
          <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.customBtn {
  margin: 1%;
}
</style>