<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <span class="master-detail-caption">Pencarian Item Verifikasi</span>
      <form action="/auditFindingItem" @submit.prevent="handleSubmit">
        <DxSelectBox
          class="custom-form"
          :data-source="unitBisnisOptions"
          label="Pilih Unit Bisnis"
          value-expr="id"
          display-expr="name"
          @valueChanged="changeUnitBisnis"
          v-model="options.company"
        >
        </DxSelectBox>
        <DxSelectBox
          class="custom-form"
          :data-source="departmentOptions"
          label="Pilih Department"
          value-expr="department_id"
          display-expr="department_name"
          @valueChanged="changeDepartment"
          v-model="options.department"
          :read-only="readOnlyDept"
        >
        </DxSelectBox>
        <div v-if="checklistHide">
          <DxTextBox
            class="custom-form"
            label="Clausul Relevan"
            v-model:value="auditChecklist.clausul_relevan"
            :read-only="readOnlyCheck"
          >
          </DxTextBox>
          <DxTextArea
            class="custom-form"
            label="Proses"
            :height="60"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="auditChecklist.process_name"
            :read-only="readOnlyCheck"
          >
          </DxTextArea>
          <DxTextArea
            class="custom-form"
            label="Lingkup Aktivitas"
            :height="60"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="auditChecklist.activity"
            :read-only="readOnlyCheck"
          >
          </DxTextArea>
          <DxTextBox
            class="custom-form"
            label="PIC"
            v-model:value="auditChecklist.pic"
            :read-only="readOnlyCheck"
          >
          </DxTextBox>
          <DxTextArea
            class="custom-form"
            label="Potensi Risiko"
            :height="60"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="auditChecklist.potential"
            :read-only="readOnlyCheck"
          >
          </DxTextArea>
          <DxTextBox
            class="custom-form"
            label="Tingkat Risiko"
            v-model:value="auditChecklist.risk_level"
            :read-only="readOnlyCheck"
          >
          </DxTextBox>
          <DxTextArea
            class="custom-form"
            label="Pengendalian Risiko"
            :height="60"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="auditChecklist.risk_control_name"
            :read-only="readOnlyCheck"
          >
          </DxTextArea>
          <DxTextBox
            class="custom-form"
            label="Tipe Pengendalian Risiko"
            v-model:value="auditChecklist.type"
            :read-only="readOnlyCheck"
          >
          </DxTextBox>
        </div>
        <DxSelectBox
          v-if="!itemEnable"
          class="custom-form"
          :data-source="verificationOptions"
          label="Pilih Verifikasi Item *"
          value-expr="id"
          display-expr="verification_items"
          v-model="auditFinding.verification"
          :read-only="readOnlyVerf"
          :search-enabled="true"
          @valueChanged="changeVerificationItem"
          item-template="item"
        >
          <template #item="{ data: item, index }">
            <div class="custom-item">
              <div class="product-name">
                <!-- BUAT ITEM OTOMATIS HILANG SETELAH DIPAKAI -->
                <!-- <span
                  class="notification"
                  :class="{
                    Ngreen: item.verification_items == 'ISO',
                    Nyellow: item.verification_items != 'ISO',
                  }"
                ></span> -->
                <!-- <span>  </span> -->
                <b>{{ index + 1 }}. {{ item.verification_items }}</b>
                <span>
                  / Proses :
                  {{
                    item.risk_control.potential.pic_checklist.activity.process
                      .process_name
                  }}</span
                >
                <span>
                  / Clausul :
                  {{
                    item.risk_control.potential.pic_checklist.activity.process
                      .checklist.clausul_relevan
                  }}
                </span>
              </div>
            </div>
          </template>
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>
        <DxTextBox
          v-if="itemEnable"
          class="custom-form"
          label="Verifikasi Item *"
          v-model:value="auditFinding.item"
          :read-only="true"
        >
        </DxTextBox>
      </form>
    </div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addAuditFinding" @submit.prevent="handleSubmit">
        <DxTextArea
          class="custom-form"
          label="Catatan Hasil Verifikasi *"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="auditFinding.verification_desc"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextArea>

        <DxSelectBox
          class="custom-form"
          :data-source="verificationStatusOptions"
          label="Pilih Status Verifikasi *"
          value-expr="name"
          display-expr="name"
          :is-required="true"
          v-model="auditFinding.verification_status"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxTextBox
          class="custom-form"
          label="Auditee"
          v-model:value="auditFinding.auditee"
        >
        </DxTextBox>

        <DxTextBox
          class="custom-form"
          label="Auditor"
          v-model:value="auditFinding.auditor"
        >
        </DxTextBox>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
            
  <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      auditFinding: {},
      readOnly: false,
      verificationStatusOptions: [
        { name: "OK" },
        { name: "NC" },
        { name: "Obs" },
      ],
      unitBisnisOptions: "",
      departmentOptions: "",
      verificationOptions: "",
      auditChecklist: {},
      readOnlyCheck: true,
      readOnlyDept: true,
      readOnlyVerf: true,
      checklistHide: false,
      options: {
        company: null,
        department: null,
      },
      itemEnable: false,
      paramId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("auditFinding", ["getAuditFinding", "getAuditFindingStatus"]),
    ...mapGetters("auditVerificationItem", ["getAuditVerificationItem"]),
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      this.itemEnable = true;
      if (this.getAuditFinding) {
        this.auditFinding = this.getAuditFinding;
        this.auditFinding.item =
          this.getAuditFinding.verification_item.verification_items;
      } else {
        this.loadFinding(this.paramId);
      }
    }
    this.unitBisnis();
  },
  props: {},
  emits: ["get-audit-finding"],
  methods: {
    async loadFinding(params) {
      try {
        const getFinding = await this.$store.dispatch(
          "auditFinding/getAuditFinding",
          `/${params}`
        );
        this.auditFinding = getFinding.data;
        this.auditFinding.item =
          getFinding.data.verification_item.verification_items;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    async changeVerificationItem(params) {
      if (params.value) {
        const text = `/checklist/${params.value}`;
        const getChecklistJoin = await this.$store.dispatch(
          "auditVerificationItem/getAuditVerificationItem",
          text
        );
        this.checklistHide = true;

        this.auditChecklist = getChecklistJoin.data;
      } else {
        return;
      }
    },
    async changeDepartment(params) {
      if (params.value) {
        const text = `?department=${params.value}&limit=999999`;
        const auditVerificationItem = await this.$store.dispatch(
          "auditVerificationItem/getAuditVerificationItem",
          text
        );
        this.readOnlyVerf = false;
        this.checklistHide = false;
        this.itemEnable = false;
        if (!this.paramId) {
          this.auditFinding.verification = null;
        }
        this.verificationOptions = auditVerificationItem.data;
      }
    },
    async changeUnitBisnis(params) {
      const text = `?company=${params.value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );

      this.readOnlyDept = false;
      this.checklistHide = false;
      if (!this.paramId) {
        this.readOnlyVerf = true;
        this.options.department = null;
        this.auditFinding.verification = null;
      }
      this.departmentOptions = depart.data;
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
      this.unitBisnisOptions = units.data;
    },
    async handleSubmit(e) {
      this.loading = true;
      if (!this.paramId) {
        this.auditFinding.company_id = this.auditChecklist.company_id;
        this.auditFinding.department_id = this.auditChecklist.department_id;
        this.auditFinding.section_id = this.auditChecklist.section_id;
        this.auditFinding.subsection_id = this.auditChecklist.subsection_id;
        this.auditFinding.audit_checklist_id = this.auditChecklist.checklist_id;
        if (this.auditFinding.verification_status == "OK") {
          this.auditFinding.status = true;
        }
      }
      if (this.auditFinding.verification) {
        this.auditFinding.verification_item_id = this.auditFinding.verification;
      }
      this.$emit("get-audit-finding", this.auditFinding);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
            
            <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.dx-list-item-content {
  white-space: normal;
}
.notification {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: bold;
  padding-right: 5px;
}
.Ngreen {
  background-color: green;
}
.Nyellow {
  background-color: orange;
}
</style>