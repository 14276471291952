<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addAuditVerificationItem" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in auditVerification" :key="index">
          <DxSelectBox
            class="custom-form"
            :data-source="riskControlOption"
            label="Pilih Pengendalian Risiko"
            value-expr="id"
            display-expr="name"
            :is-required="true"
            v-model="form.audit_risk_control_id"
            :search-enabled="true"
            item-template="item"
            :read-only="readOnly"
            @valueChanged="changeRiskControl(form, index)"
          >
            <template #item="{ data: item }">
              <div class="custom-item">
                <div class="product-name">
                  <b>{{ item.risk_control }}</b>
                  <span>
                    / Potensi Risiko : {{ item.potential.potential }}</span
                  >
                  <span> / PIC : {{ item.potential.pic_checklist.pic }}</span>
                  <span>
                    / Lingkup Aktivitas :
                    {{ item.potential.pic_checklist.activity.activity }}</span
                  >
                  <span>
                    / Proses :
                    {{
                      item.potential.pic_checklist.activity.process.process_name
                    }}</span
                  >
                  <span>
                    / Clausul :
                    {{
                      item.potential.pic_checklist.activity.process.checklist
                        .clausul_relevan
                    }}
                    ({{
                      departmentName({
                        value:
                          item.potential.pic_checklist.activity.process
                            .checklist.department_id,
                      })
                    }})</span
                  >
                </div>
              </div>
            </template>
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>

          <DxTextArea
            class="custom-form"
            label="Item Verifikasi"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="form.verification_items"
          >
          </DxTextArea>
          <DxTextArea
            class="custom-form"
            label="Deskripsi"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="form.desc"
          >
          </DxTextArea>
          <DxButton
            v-if="auditVerification.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading" class="custom-form">
          <DxButton
            v-if="!paramId && auditVerification.length < 10"
            class="customBtn"
            text="Form"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            class="customBtn"
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else class="custom-form" />
      </form>
    </div>
  </div>
</template>
            
  <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      auditVerification: [
        {
          audit_checklist_id: "",
          audit_risk_control_id: "",
          verification_items: "",
          desc: "",
        },
      ],
      readOnly: false,
      verificationStatusOptions: [
        { name: "OK" },
        { name: "NC" },
        { name: "Obs" },
      ],
      riskControlOption: {},
      paramId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("auditVerificationItem", [
      "getAuditVerificationItem",
      "getAuditVerificationItemStatus",
    ]),
    ...mapGetters("auditRiskControl", ["getAuditRiskControl"]),
    ...mapGetters("auditChecklist", ["getAuditChecklist"]),
    ...mapGetters("department", ["getAllDepartment"]),
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      this.readOnly = true;
      console.log(this.riskControlOption, "riskoption");

      if (this.auditVerificationItem) {
        this.auditVerification[0] = this.auditVerificationItem;
      } else {
        this.loadItemVerif(this.paramId);
      }
    }
    this.getRiskControl();
  },
  props: {},
  emits: ["get-audit-verification-item"],
  methods: {
    changeRiskControl(form, index) {
      if (!this.paramId && this.paramId.trim() == "") {
        const selectClausul = this.riskControlOption.find(
          (item) => item.id === form.audit_risk_control_id
        );
        this.auditVerification[index].audit_checklist_id =
          selectClausul.potential.pic_checklist.activity.process.checklist_id;
        this.auditVerification[index].clausule =
          selectClausul.potential.pic_checklist.activity.process.checklist.clausul_relevan;
      }
      console.log("dilakukan");
    },
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "-";
      }
      return `${depart.company_name} | ${depart.department_name}`;
    },
    async loadItemVerif(params) {
      try {
        const getItem = await this.$store.dispatch(
          "auditVerificationItem/getAuditVerificationItem",
          `/${params}`
        );

        this.auditVerification[0] = getItem.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.auditVerification.push({
        audit_checklist_id: "",
        audit_risk_control_id: "",
        verification_items: "",
        desc: "",
      });
    },
    removeForm(index) {
      this.auditVerification.splice(index, 1);
    },
    async getRiskControl() {
      const text = `?limit=999999`;
      const getRControl = await this.$store.dispatch(
        "auditRiskControl/getAuditRiskControl",
        text
      );
      this.riskControlOption = getRControl.data;
    },
    async handleSubmit(e) {
      this.loading = true;
      this.$emit("get-audit-verification-item", this.auditVerification);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
            
            <style scoped>
.customBtn {
  margin: 1%;
}
.custom-form {
  margin-top: 10px;
}
.dx-list-item-content {
  white-space: normal;
}
</style>