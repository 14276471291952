<template>
  <div>
    <h2 class="content-block">
      {{ getAuditFindingStatus.action == "Add" ? "Membuat" : "Edit" }}
      Catatan Hasil Verifikasi
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formAuditFinding @get-audit-finding="submit"></formAuditFinding>
  </div>
</template>
              <script>
import formAuditFinding from "@/components/audit/formAuditFinding.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formAuditFinding,
    DxButton,
  },
  data() {
    return {
      auditReport: {},
    };
  },
  computed: {
    ...mapGetters("auditFinding", ["getAuditFindingStatus"]),
    // ...mapGetters("auditPlan", ["getAuditPlan"]),
    ...mapGetters("auditChecklist", ["getAuditChecklist"]),
    // setAuditReport() {
    // this.auditReport.company_id = this.getAuditChecklist.company_id;
    // this.auditReport.department_id = this.getAuditChecklist.department_id;
    // this.auditReport.section_id = this.getAuditChecklist.section_id;
    // this.auditReport.subsection_id = this.getAuditChecklist.subsection_id;
    // this.auditReport.branch_id = this.getAuditChecklist.branch_id;
    // this.auditReport.checklist_id = this.getAuditChecklist.id;
    // this.auditReport.reference = this.getAuditChecklist.clausul_relevan;
    // },
  },
  mounted() {},
  methods: {
    // buat add audit report ketika status nya nc dan obs
    async submit(value) {
      this.auditReport.checklist_id = value.audit_checklist_id;
      if (this.getAuditFindingStatus.action == "Add") {
        this.$store
          .dispatch("auditFinding/postAuditFinding", value)
          .then((res) => {
            // if (res.data.verification_status != "OK") {
            //   this.auditReport.company_id = res.data.company_id;
            //   this.auditReport.department_id = res.data.department_id;
            //   this.auditReport.section_id = res.data.section_id;
            //   this.auditReport.subsection_id = res.data.subsection_id;
            //   this.auditReport.audit_finding_id = res.data.id;

            //   this.addAuditReport();
            // }
            console.log(value, "value st");

            if (value.status) {
              router.push(`/admin/audit-history-finding/info`);
            } else {
              router.push(`/admin/audit-finding/info`);
            }

            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getAuditFindingStatus.action == "Edit") {
        this.$store
          .dispatch("auditFinding/editAuditFinding", value)
          .then((res) => {
            // if (this.getAuditFindingStatus.roles == "user") {
            //   router.push(`/user/risk/info`);
            // } else if (this.getAuditFindingStatus.roles == "suAdmin") {
            //   router.push(`/super-admin/risk/info`);
            // }
            router.push(`/admin/audit-finding/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
    addAuditReport() {
      this.$store
        .dispatch("auditReport/postAuditReport", this.auditReport)
        .then((res) => {
          console.log(res.data, "audit report");
        })
        .catch((e) => {
          console.log(e);
          notify(`Audit Report ${e.message}`, "error", 2000);
          return;
        });
    },
  },
};
</script>