<template>
  <!-- <div class="widget-container"> -->
  <div class="content-block dx-card" style="padding: 15px">
    <div><b>Filter</b></div>
    <div class="input-group">
      <DxSelectBox
        class="group"
        :data-source="unitBisnisOptions"
        label="Pilih Unit Bisnis"
        value-expr="id"
        display-expr="name"
        :is-required="true"
        v-model.value="valueUnitBisnis"
        @valueChanged="changeUnitBisnis()"
      >
      </DxSelectBox>
      <DxSelectBox
        class="group"
        :data-source="departmentOptions"
        label="Pilih Department"
        value-expr="department_id"
        display-expr="department_name"
        :is-required="true"
        :read-only="readOnlyDept"
        v-model.value="valueDepartment"
        @valueChanged="changeDepartment()"
      >
      </DxSelectBox>
    </div>
    <DxButton
      v-if="!readOnlyDept"
      class="custom-form"
      type="danger"
      icon="fa fa-trash-arrow-up"
      hint="Reset"
      stylingMode="outlined"
      @click="clear()"
    />
  </div>
  <!-- </div> -->
</template>

<script>
import { DxSelectBox } from "devextreme-vue/select-box";
import DxButton from "devextreme-vue/button";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      unitBisnisOptions: "",
      departmentOptions: "",
      valueUnitBisnis: "",
      valueDepartment: "",
      readOnlyDept: true,
    };
  },
  computed: {
    ...mapGetters("department", ["getAllDepartment"]),
    ...mapGetters("unitBisnis", ["getAllUnitBisnis"]),
  },
  mounted() {
    // this.unitBisnis();
    this.unitBisnisOptions = this.getAllUnitBisnis;
  },
  emits: ["get-department", "get-unit-bisnis"],
  methods: {
    clear() {
      this.valueUnitBisnis = "";
      this.valueDepartment = "";
    },
    changeDepartment() {
      this.$emit("get-department", this.valueDepartment);
    },
    async changeUnitBisnis() {
      if (this.valueUnitBisnis) {
        // const text = `?company=${this.valueUnitBisnis}`;
        // const depart = await this.$store.dispatch(
        //   "department/getDepartments",
        //   text
        // );
        const depart = this.getAllDepartment?.filter(
          (e) => e.company_id == this.valueUnitBisnis
        );
        this.departmentOptions = depart;

        this.readOnlyDept = false;
      } else {
        this.readOnlyDept = true;
      }
      this.valueDepartment = "";
      this.$emit("get-unit-bisnis", this.valueUnitBisnis);
      return;
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
      this.unitBisnisOptions = units.data;
    },
  },
  components: {
    DxSelectBox,
    DxButton,
  },
};
</script>
<style scoped>
.custom-form {
  margin-top: 20px;
}
.group {
  display: inline-block;
}
.input-group {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Auto-fit columns with a minimum width */
  gap: 10px; /* Optional: space between input elements */
  margin-top: 10px;
}
</style>