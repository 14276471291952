<template>
  <div class="widget-container">
    <h2 class="content-block">
      {{
        getRequestDocumentStatus == "Add" ||
        getRequestDocumentStatus == "Request"
          ? "Membuat"
          : "Edit"
      }}
      Permintaan Dokumen
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <div>
      <formRequestDoc @get-request-document="saveData"></formRequestDoc>
    </div>
  </div>
</template>
  
  <script>
import DxButton from "devextreme-vue/button";
import notify from "devextreme/ui/notify";
import router from "@/router";
import formRequestDoc from "@/components/documentManagement/formRequestDocumentOld.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapGetters("requestDocument", ["getRequestDocumentStatus"]),
  },
  methods: {
    async saveData(params) {
      if (
        this.getRequestDocumentStatus == "Add" ||
        this.getRequestDocumentStatus == "Request"
      ) {
        this.$store
          .dispatch("requestDocument/postRequestDocument", params)
          .then((res) => {
            notify("berhasil", "success", 2000);
            router.push("/super-admin/req-doc/info");
          })
          .catch((e) => {
            console.log(e);
            notify("gagal", "error", 2000);
          });
      } else if (this.getRequestDocumentStatus == "Edit") {
        this.$store
          .dispatch("requestDocument/updateRequestDocument", params)
          .then((res) => {
            notify("berhasil", "success", 2000);
            router.push("/super-admin/req-doc/info");
          })
          .catch((e) => {
            console.log(e);
            notify("gagal", "error", 2000);
          });
      } else {
        notify("gagal", "error", 2000);
      }
    },
  },
  components: {
    formRequestDoc,
    DxButton,
  },
};
</script>
  
  <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>