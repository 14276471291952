import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            improvementPoint: null,
            improvementPointStatus: null,
        }
    },
    mutations: {
        setImprovementPoint(state, payload) {
            state.improvementPoint = payload
        },
        setImprovementPointStatus(state, payload) {
            state.improvementPointStatus = payload
        },
    },
    actions: {
        setImprovementPoint({ commit }, params) {
            commit('setImprovementPoint', params)
        },
        setImprovementPointStatus({ commit }, params) {
            commit('setImprovementPointStatus', params)
        },
        async getImprovementPoint({ commit, state }, params) {
            return await Api().get(
                `improvement-points${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postImprovementPoint({ commit, state }, params) {
            return await Api().post(
                `improvement-points`, params
            ).then((res) => {
                return res.data
            })
        },
        async editImprovementPoint({ commit, state }, params) {
            return await Api().put(
                `improvement-points/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyImprovementPoint({ commit, state }, params) {
            return await Api().delete(
                `improvement-points/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getImprovementPoint(state) {
            return state.improvementPoint;
        },
        getImprovementPointStatus(state) {
            return state.improvementPointStatus;
        },
    }
}