<template>
  <div>
    <dx-data-grid
      class="dx-card wide-card table-left"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :word-wrap-enabled="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          text="edit"
          hint="edit"
          icon="fa fa-pen-to-square"
          @click="edit"
          v-if="permissions.canUpdate && (auditee || history)"
        />
        <DxButton
          hint="hapus"
          icon="fa fa-trash"
          @click="popupDestroy"
          v-if="permissions.canDelete && (auditee || history)"
        />
      </dx-column>
      <dx-column data-field="action" caption="Perbaikan Sementara" />
    </dx-data-grid>

    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
        <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";
import tableScopeActivities from "@/components/audit/tableScopeActivities.vue";

import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    DxButtonx,
    tableScopeActivities,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 9999,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            search: filterValue,
            report: vthis.auditReport.id,
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "process-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      popupDelete: false,
      deleteData: {},
      permissions: {
        canCreate: null,
        canUpdate: null,
        canDelete: null,
        canVerified: null,
        unfilteredData: null,
      },
    };
  },
  mounted() {},
  created() {
    const permission = this.$route.meta.permission;
    // check permission
    this.permissions.canCreate = (permission & 2) > 0;
    this.permissions.canUpdate = (permission & 4) > 0;
    this.permissions.canVerified = (permission & 8) > 0;
    this.permissions.canDelete = (permission & 16) > 0;
    this.permissions.unfilteredData = (permission & 32) > 0;
  },
  props: {
    roles: {
      type: String,
    },
    auditReport: {
      type: Object,
    },
    history: {
      type: Boolean,
      default: false,
    },
    auditee: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
  },
  methods: {
    confirmDestroy() {
      this.$store
        .dispatch("auditPrevective/destroyAuditPrevective", this.deleteData)
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;

      this.deleteData = params.row.data;
    },
    edit(params) {
      this.$store.dispatch(
        "auditPrevective/setAuditPrevective",
        params.row.data
      );
      router.push(`/audit-prevective/form/edit/${params.row.key}`);
    },
    onToolbarPreparing(e) {
      const vThis = this;
      e.toolbarOptions.items.unshift({
        location: "before",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
      if (vThis.permissions.canCreate && (vThis.auditee || vThis.history)) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              router.push(`/audit-prevective/form/add/${vThis.auditReport.id}`);
            },
          },
        });
      }
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "auditPrevective/getAuditPrevective",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
};
</script>
        <style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
.master-detail-caption {
  padding: 0 0 5px 10px;
  font-size: 14px;
  font-weight: bold;
}
.table-left {
  width: 60%;
  margin: left;
}
</style>