<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/auditImplementation" @submit.prevent="handleSubmit">
        <div class="fileuploader-container custom-form">
          <DxFileUploader
            select-button-text="Pilih Dokumen"
            label-text="upload file"
            :allowed-file-extensions="fileAllowed"
            :max-file-size="6000000"
            upload-mode="useForm"
            @value-changed="getFile"
            :validationError="validationError"
          >
          </DxFileUploader>
        </div>
        <div v-if="validationError" style="color: red">File is required.</div>
        <div v-if="paramsRoute.condition == 'edit' && !file">
          File Lama : {{ auditImplementation.proof_file }}
        </div>
        <div style="text-align: right; margin-bottom: 10px">
          <span>{{ fileAllowed }}</span> - <span>Max : 5 MB</span>.
        </div>

        <!-- <DxSelectBox
          class="custom-form"
          :data-source="tipeAuditImplOptions"
          label="Pilih Tipe Bukti"
          value-expr="value"
          display-expr="name"
          :is-required="true"
          v-model="auditImplementation.proof_type"
        >
          <DxValidator> <DxRequiredRule message="Required" /> </DxValidator
        ></DxSelectBox> -->
        <DxTextArea
          class="custom-form"
          label="Keterangan"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="auditImplementation.desc"
        >
        </DxTextArea>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
              
    <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      auditImplementation: {},
      tipeAuditImplOptions: [
        // { value: "Before", name: "Kondisi Sebelumnya (Before)" },
        { value: "After", name: "Kondisi Sesudah (After)" },
      ],
      readOnly: false,
      file: "",
      fileAllowed: [".png", ".jpg", ".jpeg", ".docx", ".doc", ".pdf", ".xlsx"],
      validationError: false,
      paramsRoute: this.$route.params,
    };
  },
  computed: {
    ...mapGetters("auditImplementation", [
      "getAuditImplementation",
      "getAuditImplementationStatus",
    ]),
    ...mapGetters("auditReport", ["getAuditReport"]),
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      if (this.getAuditImplementation) {
        this.auditImplementation = this.getAuditImplementation;
      } else {
        this.loadAuditImpl(this.paramsRoute.id);
      }
    } else {
      if (this.paramsRoute.type == "before") {
        this.auditImplementation.proof_type = "Before";
      } else {
        this.auditImplementation.proof_type = "After";
      }
    }
  },
  emits: ["get-audit-impl"],
  methods: {
    async loadAuditImpl(params) {
      try {
        const getAuditImpl = await this.$store.dispatch(
          "auditImplementation/getAuditImplementation",
          `/${params}`
        );

        this.auditImplementation = getAuditImpl.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    getFile(params) {
      if (params.component._files.length > 0) {
        this.loading = !params.component._files[0].isValidFileExtension;
        this.file = params.value[0];

        this.validationError = false;
      }
    },
    async handleSubmit(e) {
      if (!this.file && this.paramsRoute.condition == "add") {
        this.validationError = true; // Set validation error if no files
        return;
      }
      this.loading = true;
      this.auditImplementation.proof_file = this.file;

      this.$emit("get-audit-impl", this.auditImplementation);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxFileUploader,
    DxLoadIndicator,
  },
};
</script>
              
              <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 0;
}
</style>