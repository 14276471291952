<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addAuditReport" @submit.prevent="handleSubmit">
        <div class="custom-form">
          <div class="label-radio">Jenis Temuan *</div>
          <DxRadioGroup
            :items="FindingType"
            value-expr="id"
            display-expr="name"
            v-model:value="auditReport.finding_type_id"
            layout="horizontal"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxRadioGroup>
        </div>

        <div class="custom-form">
          <div class="label-radio">Faktor Temuan *</div>
          <DxRadioGroup
            :items="factorFinding"
            value-expr="id"
            display-expr="name"
            v-model:value="auditReport.factor_finding_id"
            layout="horizontal"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxRadioGroup>
        </div>

        <div class="custom-form">
          <div class="label-radio">Standard Penyimpangan *</div>
          <DxRadioGroup
            :items="aberrationStandart"
            value-expr="id"
            display-expr="name"
            v-model:value="auditReport.aberration_standart_id"
            layout="horizontal"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxRadioGroup>
        </div>

        <div class="custom-form">
          <div class="label-radio">Klasifikasi Kesalahan *</div>
          <DxRadioGroup
            :items="faultClassification"
            value-expr="id"
            display-expr="name"
            v-model:value="auditReport.fault_classification_id"
            layout="horizontal"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxRadioGroup>
        </div>

        <div class="custom-form">
          <div class="label-radio">Tipe Kesalahan *</div>
          <DxRadioGroup
            :items="faultType"
            value-expr="id"
            display-expr="name"
            v-model:value="auditReport.fault_type_id"
            layout="horizontal"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxRadioGroup>
        </div>

        <div class="custom-form">
          <div class="label-radio">Risiko Temuan *</div>
          <DxRadioGroup
            :items="riskFinding"
            v-model:value="auditReport.risk_finding"
            layout="horizontal"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxRadioGroup>
        </div>

        <div class="custom-form">
          <div class="label-radio">Tipe Temuan *</div>
          <DxRadioGroup
            :items="findingDetailType"
            v-model:value="auditReport.finding_detail_type"
            layout="horizontal"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxRadioGroup>
        </div>
        <DxSelectBox
          class="custom-form"
          :data-source="unitBisnisOptions"
          label="Pilih Unit Bisnis *"
          value-expr="id"
          display-expr="name"
          :is-required="true"
          @valueChanged="changeUnitBisnis"
          v-model:value="auditReport.company_id"
          :read-only="!enableSelect"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxTextBox
          v-if="paramId && paramId.trim() !== ''"
          class="custom-form"
          label="department"
          v-model:value="auditReport.department_name"
          :read-only="true"
        >
        </DxTextBox>

        <div v-if="enableSelect">
          <DxSelectBox
            class="custom-form"
            :data-source="departmentOptions"
            label="Pilih Department *"
            value-expr="department_id"
            display-expr="department_name"
            v-model:value="auditReport.department_id"
            @valueChanged="changeDepartment"
            :is-required="true"
            :read-only="readOnlyDept"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
        </div>

        <DxSelectBox
          class="custom-form"
          :data-source="sectionOptions"
          label="Pilih Section"
          value-expr="section_id"
          display-expr="section_name"
          v-model:value="auditReport.section_id"
          :read-only="readOnlySec"
          :show-clear-button="true"
        >
        </DxSelectBox>

        <DxSelectBox
          class="custom-form"
          :data-source="branchOptions"
          label="Pilih Lokasi *"
          value-expr="location_id"
          display-expr="location_name"
          v-model:value="auditReport.branch_id"
          :is-required="true"
          :search-enabled="true"
          search-expr="location_name"
          :read-only="readOnlyBran"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxSelectBox
          class="custom-form"
          :data-source="auditPlanOptions"
          label="Pilih Agenda Audit *"
          value-expr="id"
          display-expr="event_name"
          v-model="auditReport.audit_plan_id"
          :is-required="true"
          item-template="customItem"
          :search-enabled="true"
          search-expr="event_name"
          :read-only="readOnlySec"
        >
          <template #customItem="{ data: itemData }">
            <div>
              <div>
                <b>Nama Agenda : </b>
                {{ itemData.event_name }}({{ itemData.audit_category }}) ||
                <b>Tanggal Audit : </b
                ><span
                  >{{ formatIsoDate(itemData.start_date) }} -
                  {{ formatIsoDate(itemData.end_date) }}</span
                >
              </div>
            </div>
          </template>
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <div class="custom-form">
          <DxTagBox
            :search-enabled="true"
            :data-source="verificationItemsOptions"
            label="Pilih Catatan Hasil Verifikasi *"
            display-expr="verification_desc"
            value-expr="id"
            v-model.value="valueFindings"
            :show-selection-controls="true"
            :maxFilterQueryLength="99999"
            item-template="item"
            :read-only="readOnlyFind"
          >
            <template #item="{ data: item }">
              <div class="custom-item">
                <div class="product-name">
                  {{ item.verification_desc }} || {{ item.verification_status }}
                </div>
              </div>
            </template>
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTagBox>
          <div
            style="text-align: right; margin-bottom: 10px"
            v-if="!disableFinding"
          >
            <a @click.prevent="resetFinding" href="#">Reset Temuan</a>
          </div>
        </div>

        <DxTextArea
          class="custom-form"
          label="Bukti Objektif *"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="auditReport.proof_objective"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextArea>

        <DxDateBox
          class="custom-form"
          label="Deadline *"
          :input-attr="{ 'aria-label': 'Date' }"
          type="date"
          v-model:value="auditReport.deadline"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxDateBox>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>

      <!-- popup reset finding -->
      <DxPopup
        :width="300"
        height="auto"
        :show-title="true"
        title="Konfirmasi"
        :drag-enabled="false"
        :hide-on-outside-click="true"
        v-model:visible="popupResetFinding"
      >
        <template #content="{}">
          <div class="popup-property-details">
            <b>Apakah yakin reset temuan ?</b>
            <div class="justify-center">
              <DxButton
                class="customBtn"
                text="ya"
                icon="check"
                type="success"
                @click="confirmDestroy()"
              />
              <DxButton
                class="customBtn"
                text="batal"
                icon="close"
                type="danger"
                @click="popupResetFinding = false"
              />
            </div>
          </div>
        </template>
      </DxPopup>
    </div>
  </div>
</template>
                
          <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import DxDateBox from "devextreme-vue/date-box";
import DxRadioGroup from "devextreme-vue/radio-group";
import DxTagBox from "devextreme-vue/tag-box";
import { DxHtmlEditor } from "devextreme-vue/html-editor";
import DxPopup from "devextreme-vue/popup";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";

export default {
  data() {
    return {
      loading: false,
      loadIndicatorVisible: false,
      auditReport: {},
      tipeAuditOptions: [{ name: "Internal" }, { name: "Eksternal" }],
      methodOptions: [{ name: "Offline" }, { name: "Online" }],
      criteriaAuditOptions: [{ name: "QMS" }, { name: "HSE" }],
      auditPlanOptions: "",
      unitBisnisOptions: "",
      departmentOptions: "",
      sectionOptions: "",
      branchOptions: "",
      readOnlyDept: true,
      readOnlySec: true,
      readOnlyBran: true,
      FindingType: [],
      factorFinding: [],
      aberrationStandart: [],
      faultClassification: [],
      faultType: [],
      riskFinding: ["Low", "Medium", "High"],
      findingDetailType: ["Berulang", "Tidak Berulang"],
      verificationItemsOptions: {},
      readOnlyFind: true,
      valueFindings: [],
      dataEdit: {
        findings: "",
        company: "",
        department: "",
      },
      disableFinding: true,
      popupResetFinding: false,
      paramId: this.$route.params.id,
      enableSelect: true,
    };
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      this.enableSelect = false;
      if (this.getAuditReport) {
        this.auditReport = this.getAuditReport;

        // getAuditPlan & Section Options
        this.getAuditPlan(this.getAuditReport.department_id);
        this.getSection(this.getAuditReport.department_id);
        this.readOnlySec = false;
        this.readOnlyBran = false;
        // end Options

        // getName
        this.auditReport.department_name = this.findDepartment(
          this.getAuditReport.department_id
        );
        this.auditReport.section_name = this.findSection(
          this.getAuditReport.section_id
        );
        this.auditReport.branch_name = this.findBranch(
          this.getAuditReport.branch_id
        );
        // End getName

        // getFinding group
        const findings = this.getAuditReport.audit_group_finding;
        // CHECK GROUPNYA ADA NGGK KALO ADA PAKE TRUE KLO GK ADA YANG FALSE

        if (findings.length > 0) {
          this.getFinding(this.auditReport.department_id);
          this.disableFinding = false;
          findings.forEach((element) => {
            this.valueFindings.push(element.audit_finding_id);
          });
        } else {
          this.getFinding(this.auditReport.department_id, false);
          this.readOnlyFind = false;
        }
        // end getFinding group
      } else {
        this.loadAuditReport(this.paramId);
      }
    }

    this.unitBisnis();
    this.getItemAuditReport();
  },
  emits: ["get-audit-report"],
  computed: {
    ...mapGetters("auditReport", ["getAuditReport", "getAuditReportStatus"]),
    ...mapGetters("authentication", ["getUserDetail"]),
    ...mapGetters("branch", ["getAllBranch"]),
    ...mapGetters("section", ["getAllSection"]),
    ...mapGetters("department", ["getAllDepartment"]),
  },
  methods: {
    findDepartment(params) {
      const department = this.getAllDepartment.find(
        (res) => res.department_id === params
      );
      return department.department_name || "-";
    },
    findSection(params) {
      if (params) {
        const section = this.getAllSection.find(
          (res) => res.section_id == params
        );
        return section.section_name;
      }
    },
    findBranch(params) {
      const branch = this.getAllBranch.find(
        (res) => res.location_id === params
      );
      return branch.location_name;
    },
    async getAuditPlan(params) {
      const text = `?limit=9999&department_id=${params}&agenda_type=Agenda Audit&status=true`;
      const getAuditPlan = await this.$store.dispatch(
        "auditPlan/getAuditPlan",
        text
      );
      this.auditPlanOptions = getAuditPlan.data;
    },
    async loadAuditReport(params) {
      try {
        const getAgenda = await this.$store.dispatch(
          "auditReport/getAuditReport",
          `/${params}`
        );

        this.auditReport = getAgenda.data;

        // getAuditPlan & Section Options
        this.getAuditPlan(getAgenda.data.department_id);
        this.getSection(getAgenda.data.department_id);
        this.readOnlySec = false;
        this.readOnlyBran = false;
        // end Options

        // getName
        this.auditReport.department_name = this.findDepartment(
          getAgenda.data.department_id
        );
        this.auditReport.section_name = this.findSection(
          getAgenda.data.section_id
        );
        this.auditReport.branch_name = this.findBranch(
          getAgenda.data.branch_id
        );
        // End getName

        // getFinding group
        const findings = getAgenda.data.audit_group_finding;
        this.getFinding(this.auditReport.department_id, true);
        if (findings.length > 0) {
          this.disableFinding = false;
          findings.forEach((element) => {
            this.valueFindings.push(element.audit_finding_id);
          });
        } else {
          this.readOnlyFind = false;
        }
        // end getFinding group
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    formatIsoDate(isoDate) {
      if (isoDate) {
        const dateObject = new Date(isoDate);

        // Mendapatkan komponen tanggal
        const day = dateObject.getDate().toString().padStart(2, "0");
        const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Bulan dimulai dari 0
        const year = dateObject.getFullYear();

        // Mendapatkan jam dan menit
        const hours = dateObject.getHours().toString().padStart(2, "0");
        const minutes = dateObject.getMinutes().toString().padStart(2, "0");

        // Mengembalikan format tanggal jam:menit
        return `${day}/${month}/${year} ${hours}:${minutes}`;
      }
    },
    async confirmDestroy() {
      // HARUSNYA KETIKA BERHASIL DIHAPUS NGELOAD TEMUAN YANG FALSE
      const dataDelete = this.auditReport.audit_group_finding;
      if (dataDelete.length > 0) {
        dataDelete.forEach((element) => {
          this.$store
            .dispatch("auditReport/destroyAuditGroupFinding", element)
            .then((res) => {})
            .catch((e) => {
              notify(e.message, "error", 2000);
            });
        });
        this.getFinding(this.auditReport.department_id, false);
        // clear form
        this.auditReport.audit_group_finding = [];
        this.valueFindings = "";
        this.disableFinding = true;
        this.readOnlyFind = false;
        notify("delete success", "success", 2000);
      }
      this.popupResetFinding = false;
    },
    async getBranch(params) {
      const getBranch = await this.$store.dispatch(
        "branch/getBranch",
        `?limit=9999&company=${params}`
      );
      this.branchOptions = getBranch.data;
    },
    resetFinding() {
      this.popupResetFinding = true;
    },
    async getFinding(params, status = "") {
      const text = `?limit=9999&department=${params}&verification_status=NC
,Obs&status=${status}`;
      const getFinding = await this.$store.dispatch(
        "auditFinding/getAuditFinding",
        text
      );
      this.verificationItemsOptions = getFinding.data;
    },
    async getSection(params) {
      const textSection = `?department=${params}&limit=9999`;
      const getSection = await this.$store.dispatch(
        "section/getSection",
        textSection
      );
      this.sectionOptions = getSection.data;
    },
    async changeDepartment(params) {
      if (params.value) {
        this.readOnlySec = false;
        this.valueFindings = "";

        // getSection
        this.getSection(params.value);

        // getFinding
        this.getFinding(params.value, false);

        // getAuditPlan
        this.getAuditPlan(params.value);

        if (!this.paramId && this.paramId.trim() == "") {
          this.readOnlyFind = false;

          this.auditReport.section_id
            ? (this.auditReport.section_id = null)
            : "";
          this.auditReport.branch_id ? (this.auditReport.branch_id = null) : "";
          this.valueFindings ? (this.valueFindings = "") : "";
          this.auditReport.audit_plan_id
            ? (this.auditReport.audit_plan_id = null)
            : "";
        }
        this.readOnlyBran = false;
      }
    },
    async changeUnitBisnis(params) {
      const text = `?company=${params.value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );

      this.getBranch(params.value);

      this.readOnlyDept = false;
      this.departmentOptions = depart.data;

      if (!this.paramId && this.paramId.trim() == "") {
        this.auditReport.department_id
          ? (this.auditReport.department_id = null)
          : "";
        this.auditReport.section_id ? (this.auditReport.section_id = null) : "";
        this.auditReport.branch_id ? (this.auditReport.branch_id = null) : "";
        this.valueFindings ? (this.valueFindings = "") : "";
        this.auditReport.audit_plan_id
          ? (this.auditReport.audit_plan_id = null)
          : "";
      }
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
      this.unitBisnisOptions = units.data;
    },
    async getAuditVerification(params) {
      const verification = await this.$store.dispatch(
        "auditVerificationItem/getAuditVerificationItem",
        `/${params}`
      );
      this.auditReport.finding_detail = verification.data.verification_desc;
    },
    async getChecklist(params) {
      const checklist = await this.$store.dispatch(
        "auditChecklist/getAuditChecklist",
        `/${params}`
      );
      this.auditReport.reference = checklist.data.clausul_relevan;
    },
    async getItemAuditReport() {
      // getRelation Audit Report
      const getFindingType = await this.$store.dispatch(
        "findingType/getFindingType",
        `?limit=999`
      );
      this.FindingType = getFindingType.data;

      const getFactorFinding = await this.$store.dispatch(
        "factorFinding/getFactorFinding",
        `?limit=999`
      );
      this.factorFinding = getFactorFinding.data;

      const getAberrationStandart = await this.$store.dispatch(
        "aberrationStandart/getAberrationStandart",
        `?limit=999`
      );
      this.aberrationStandart = getAberrationStandart.data;

      const getFaultClassification = await this.$store.dispatch(
        "faultClassification/getFaultClassification",
        `?limit=999`
      );
      this.faultClassification = getFaultClassification.data;

      const getFaultType = await this.$store.dispatch(
        "faultType/getFaultType",
        `?limit=999`
      );
      this.faultType = getFaultType.data;
    },
    handleSubmit(e) {
      this.loading = true;

      if (this.disableFinding) {
        let getfinding = this.verificationItemsOptions.filter((item) =>
          this.valueFindings.includes(item.id)
        );

        this.auditReport.findings = getfinding;
      }

      this.$emit("get-audit-report", this.auditReport);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxLoadIndicator,
    DxDateBox,
    DxRadioGroup,
    DxTagBox,
    DxHtmlEditor,
    DxPopup,
  },
};
</script>
          
          <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}

#button .button-indicator {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.center {
  justify-content: space-between;
  display: flex;
  margin-bottom: 25px;
  margin-top: 10px;
}
.group {
  display: inline-block;
}

.label-radio {
  color: darkgray;
  display: block;
}
.label-radio:after {
  content: " : ";
}
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
.dx-list-item-content {
  white-space: normal;
}
</style>