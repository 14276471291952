<template>
  <div>
    <h2 class="content-block">Daftar Dokumen {{ title }}</h2>
    <filter-table-unit-bisnis
      @get-unit-bisnis="getCompany"
      @get-department="getDepartment"
      v-if="getAll || role == 'admin' || role == 'suAdmin'"
    ></filter-table-unit-bisnis>
    <br />

    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :search-value="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <DxSearchPanel :visible="true" />
      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          text="Details"
          hint="ajukan permintaan"
          icon="fa fa-paper-plane"
          @click="request"
          v-if="getAll"
        />
        <DxButton
          hint="edit"
          icon="fa fa-pen-to-square"
          @click="edit"
          v-if="role == 'admin' || role == 'suAdmin'"
        />
        <DxButton
          hint="details"
          icon="fa fa-share-from-square"
          @click="details"
          v-if="!getAll"
        />
        <DxButton
          hint="hapus"
          icon="trash"
          @click="popupDestroy"
          v-if="role == 'suAdmin'"
        />
        <DxButton hint="detail" icon="info" @click="onDetail" v-if="access" />
        <!-- v-if="role == 'suAdmin'" -->
      </dx-column>
      <dx-column data-field="document_number" caption="Nomor Dokumen" />
      <dx-column data-field="document_name" caption="Nama Dokumen" />
      <dx-column data-field="document_scope" caption="Ruang Lingkup Dokumen" />
      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{ data: related }">
        <table-related-department :template-data="related.data" />
      </template>
    </dx-data-grid>

    <DxPopup
      width="50%"
      height="auto"
      :show-title="true"
      title="Detail Akess Dokumen"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      v-model:visible="popupDetail"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <div class="dx-fieldset">
            <div class="dx-field">
              <div class="dx-field-label">Nama Pemohon</div>
              <div class="dx-field-value">: {{ detailData.fullname }}</div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">Nama Dokumen</div>
              <div class="dx-field-value">: {{ detailData.document_name }}</div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">Tanggal Dibuat</div>
              <div class="dx-field-value">
                : {{ formatDate(detailData.created_at) }}
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">Tanggal Kadaluarsa</div>
              <div class="dx-field-value">
                : {{ formatDate(detailData.expired_date) }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </DxPopup>

    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
    <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import { DxTextArea } from "devextreme-vue/text-area";
import { DxItem } from "devextreme-vue/form";
import { DxTextBox } from "devextreme-vue/text-box";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";
import notify from "devextreme/ui/notify";

import CustomStore from "devextreme/data/custom_store";
import router from "@/router";
import "whatwg-fetch";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import tableRelatedDepartment from "@/components/documentManagement/tableRelatedDepartment.vue";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";

export default {
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || `updated_at`,
            sort: sort || "desc",
            search: filterValue,
            type: vthis.docType || "",
            company: vthis.unitBisnis,
            department: vthis.department,
            related_department: "",
            approved_department: "",
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "document-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      unitBisnis: "",
      department: "",
      btnAdd: null,
      title: "",
      popupDelete: false,
      deleteDocument: {},
      popupDetail: false,
      detailData: {},
    };
  },
  props: {
    role: {
      type: String,
    },
    docType: {
      type: String,
      default: null,
    },
    getAll: {
      type: Boolean,
      default: false,
    },
    getActive: {
      type: Boolean,
      default: false,
    },
    access: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
  },
  mounted() {
    if (this.docType) {
      this.title = this.docType;
    }
  },
  methods: {
    formatDate(params) {
      if (params == null) {
        return "-";
      }
      let date = new Date(params);
      const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const mounth = parseInt(date.getMonth()) + 1;
      const mm = mounth < 10 ? "0" + mounth : mounth;

      return dd + "-" + mm + "-" + date.getFullYear();
    },
    onDetail(params) {
      this.popupDetail = true;
      this.detailData = params.row.data.docAccessRight[0];
    },
    confirmDestroy() {
      this.$store
        .dispatch("document/destroyDocument", this.deleteDocument)
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;

      this.deleteDocument = params.row.data;
    },
    request(params) {
      this.$store.dispatch(
        "requestDocument/setRequestDocument",
        params.row.data
      );
      router.push(
        `/request-document/form/Permintaan-Dokumen/${params.row.key}`
      );
    },
    onToolbarPreparing(e) {
      const vThis = this;
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
      if (this.role == "admin" || this.role == "suAdmin") {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              vThis.$store.dispatch("document/cleanDocument");
              vThis.$store.dispatch("document/setDocumentStatus", "Add");
              if (vThis.role == "admin") {
                router.push("/admin/document/add");
              } else {
                router.push("/super-admin/document/form");
              }
            },
          },
        });
      }
    },
    async getTitle(value) {
      const text = `?department=${value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );
      this.title = depart.data[0].department_name;
    },
    getCompany(value) {
      this.unitBisnis = value;
      this.dataGrid.refresh();
    },
    getDepartment(value) {
      this.department = value;
      if (value) {
        this.getTitle(value);
      } else {
        this.title = this.docType;
      }
      this.dataGrid.refresh();
    },
    async details(params) {
      this.$store.dispatch("document/setDocument", params.row.data);
      if (this.access) {
        this.$store.dispatch("docFile/setDocumentFileStatus", "Access");
      } else {
        this.$store.dispatch("docFile/setDocumentFileStatus", null);
      }

      if (this.role == "user" && !this.getAll) {
        router.push(`/user/document/doc-file`);
      } else if (this.role == "head") {
        router.push(`/verifier/verif-detail-document/doc-file`);
      } else if (this.role == "suAdmin") {
        router.push(`/super-admin/document/doc-file/info`);
      } else {
        router.push(`/admin/document/doc-file`);
      }
    },
    async edit(params) {
      this.$store.dispatch("document/setDocumentStatus", "Edit");
      this.$store.dispatch("document/setDocument", params.row.data);
      if (this.role == "admin") {
        router.push("/admin/document/add");
      } else {
        router.push("/super-admin/document/form");
      }
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      const detail = this.$store.getters["authentication/getUserDetail"];

      if (this.role == "head") {
        params.approval_null = detail.department_id;
        this.title = detail.department_name;
      } else if (this.getActive) {
        params.doc_active = detail.department_id || "-";
        this.title = detail.department_name;
      } else if (this.getAll) {
        params.all_doc_active = true;
        params.except_depart = detail.department_id;
      } else if (this.access) {
        params.access_by_user = detail.user_id || "-";
        this.title = "Akses " + detail.full_name;
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);
      if (this.role == "admin" || this.role == "suAdmin") {
        text = text;
      } else if (this.access) {
        text = `/document-access${text}`;
      } else {
        text = `/verif-document${text}`;
      }

      var results = await this.$store.dispatch(
        `document/getDocuments`,
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxTextArea,
    DxSearchPanel,
    DxButton,
    DxTextBox,
    DxMasterDetail,
    FilterTableUnitBisnis,
    tableRelatedDepartment,
    DxButtonx,
  },
};
</script>
<style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
</style>