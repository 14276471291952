<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addCodeCompany" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in codeCompany" :key="index">
          <DxSelectBox
            :read-only="readOnly"
            class="custom-form"
            :data-source="unitBisnisOptions"
            label="Pilih Unit Bisnis"
            value-expr="id"
            display-expr="name"
            :is-required="true"
            v-model.selected-item="form.company_id"
          >
          </DxSelectBox>

          <DxTextBox
            class="custom-form"
            label="Kode Unit Bisnis"
            v-model:value="form.code"
            :is-required="true"
            ><DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextBox>
          <DxButton
            v-if="codeCompany.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>
        <div v-if="!loading" class="custom-form">
          <DxButton
            v-if="!paramId && codeCompany.length < 10"
            class="customBtn"
            text="Form"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            class="customBtn"
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else class="custom-form" />
      </form>
    </div>
  </div>
</template>
        
        <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      unitBisnisOptions: [],
      codeCompany: [
        {
          company_id: "",
          code: "",
        },
      ],
      id: this.$route.params.id,
      readOnly: false,
      paramId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("unitBisnis", [
      "getCodeUnit",
      "getCodeUnitStatus",
      "getAllUnitBisnis",
    ]),
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      this.readOnly = true;
      if (this.getCodeUnit) {
        this.codeCompany[0] = this.getCodeUnit;
      } else {
        this.loadCodeCompany(this.paramId);
      }
    }
    this.unitBisnisOptions = this.getAllUnitBisnis;
  },
  emits: ["get-unit-code"],
  methods: {
    async loadCodeCompany(params) {
      try {
        const getCode = await this.$store.dispatch(
          "unitBisnis/getUnits",
          `/${params}`
        );
        this.codeCompany[0] = getCode.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.codeCompany.push({
        company_id: "",
        code: "",
      });
    },
    removeForm(index) {
      this.codeCompany.splice(index, 1);
    },
    async handleSubmit(e) {
      this.loading = true;
      this.$emit("get-unit-code", this.codeCompany);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    FilterTableUnitBisnis,
    DxLoadIndicator,
  },
};
</script>
        
        <style scoped>
.customBtn {
  margin: 1%;
}
.custom-form {
  margin-top: 10px;
}
</style>