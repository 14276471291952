import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            module: null,
            moduleStatus: null,
        }
    },
    mutations: {
        setModule(state, payload) {
            state.module = payload
        },
        setModuleStatus(state, payload) {
            state.moduleStatus = payload
        },
    },
    actions: {
        setModule({ commit }, params) {
            commit('setModule', params)
        },
        setModuleStatus({ commit }, params) {
            commit('setModuleStatus', params)
        },
        async getModulesWithItems({ commit, state }, params) {
            return await Api().get(
                `modules/with-items${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async getModules({ commit, state }, params) {
            return await Api().get(
                `modules${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postModules({ commit, state }, params) {
            return await Api().post(
                `/modules`, params
            ).then((res) => {
                return res.data
            })
        },
        async editModules({ commit, state }, params) {
            return await Api().put(
                `/modules/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyModules({ commit, state }, params) {
            return await Api().delete(
                `/modules/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getModule(state) {
            return state.module;
        },
        getModuleStatus(state) {
            return state.moduleStatus;
        },
    }
}