<template>
  <div class="content-block dx-card responsive-paddings">
    <form action="formDocumentFile" @submit.prevent="handleSubmit">
      <div>
        <div class="label-text">File Dokumen :</div>
        <div class="fileuploader-container custom-form">
          <DxFileUploader
            select-button-text="Pilih Dokumen"
            label-text="upload file lampiran *"
            :allowed-file-extensions="fileAllowed"
            :max-file-size="6000000"
            upload-mode="useForm"
            @value-changed="getFile"
            :validationError="validationError"
          >
          </DxFileUploader>
        </div>
        <div v-if="validationError" style="color: red">
          File harus sesuai dan diisi.
        </div>
        <div v-if="paramsRoute.condition == 'edit' && !file">
          File Lama : {{ documentFile.name_file }}
        </div>
        <div style="text-align: right; margin-bottom: 10px">
          <span>{{ fileAllowed }}</span> - <span>Max : 5 MB</span>.
        </div>
      </div>

      <div class="custom-form">
        <div class="label-text">Catatan Revisi</div>
        <DxHtmlEditor
          class="custom-form"
          v-model:value="documentFile.revision_note"
        >
          <DxToolbar>
            <DxItem name="undo" />
            <DxItem name="redo" />
            <DxItem name="separator" />
            <DxItem name="separator" />
            <DxItem name="bold" />
            <DxItem name="italic" />
            <DxItem name="underline" />
            <DxItem name="separator" />
            <DxItem name="orderedList" />
            <DxItem name="bulletList" />
          </DxToolbar>
        </DxHtmlEditor>
      </div>

      <DxButton
        v-if="!loading"
        text="Simpan Data"
        type="success"
        icon="save"
        :use-submit-behavior="true"
      />
      <DxLoadIndicator v-else />
    </form>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import DxTextArea from "devextreme-vue/text-area";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import DxDateBox from "devextreme-vue/date-box";
import { DxTextBox } from "devextreme-vue/text-box";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import notify from "devextreme/ui/notify";

import { DxHtmlEditor, DxToolbar, DxItem } from "devextreme-vue/html-editor";

export default {
  components: {
    DxButton,
    DxValidator,
    DxRequiredRule,
    DxTextArea,
    DxFileUploader,
    DxDateBox,
    DxTextBox,
    DxHtmlEditor,
    DxToolbar,
    DxItem,
    DxLoadIndicator,
  },
  data() {
    return {
      paramsRoute: this.$route.params,
      documentFile: {},
      file: "",
      loading: false,
      fileAllowed: [".docx", ".doc", ".pdf", ".xlsx"],
      validationError: false,
    };
  },
  emits: ["get-document-file"],
  computed: {
    ...mapGetters("docFile", ["getDocumentFile", "getDocumentFileStatus"]),
    ...mapGetters("document", ["getDocument"]),
    ...mapGetters("requestDocument", ["getRequestDocument"]),
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      this.readOnly = true;
      if (this.getDocumentFile) {
        this.documentFile = this.getDocumentFile;
      } else {
        this.loadDocumentFile(this.paramsRoute.id);
      }
    } else if (
      this.paramsRoute.condition == "add" ||
      this.paramsRoute.condition == "redirect"
    ) {
      if (this.getDocument) {
        this.documentFile.related_department =
          this.getDocument.related_department;
      } else {
        this.loadDocument(this.paramsRoute.id);
      }
    } else if (this.paramsRoute.condition == "perubahan-dokumen-baru") {
      if (this.getRequestDocument) {
        this.loadDocument(this.getRequestDocument.document_id);
        this.documentFile.document_id = this.getRequestDocument.document_id;
        this.documentFile.request_document = this.getRequestDocument;
      } else {
        this.loadRequestDocument(this.paramsRoute.id);
      }
    }
  },
  methods: {
    async loadRequestDocument(params) {
      try {
        const getReqDoc = await this.$store.dispatch(
          "requestDocument/getRequestDocuments",
          `/${params}`
        );
        this.documentFile.document_id = getReqDoc.data.document_id;
        this.documentFile.request_document = getReqDoc.data;
        this.loadDocument(getReqDoc.data.document_id);
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    async loadDocumentFile(params) {
      try {
        const getDocFile = await this.$store.dispatch(
          "docFile/getDocFiles",
          `/${params}`
        );
        this.documentFile = getDocFile.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    async loadDocument(params) {
      try {
        const getDoc = await this.$store.dispatch(
          "document/getDocuments",
          `/${params}`
        );
        this.documentFile.related_department = getDoc.data.related_department;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    getFile(params) {
      if (params.component._files.length > 0) {
        this.loading = !params.component._files[0].isValidFileExtension;
        this.validationError = !params.component._files[0].isValidFileExtension;
        this.file = params.value[0];
      }
    },
    async handleSubmit() {
      this.loading = true;
      if (
        this.paramsRoute.condition == "add" ||
        this.paramsRoute.condition == "redirect" ||
        this.paramsRoute.condition == "perubahan-dokumen-baru"
      ) {
        if (!this.file) {
          this.validationError = true; // Set validation error if no files
          return;
        }
      }
      if (this.file) {
        this.documentFile.files_url = this.file;
      }
      this.$emit("get-document-file", this.documentFile);
    },
  },
};
</script>

<style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.label-text {
  color: darkgray;
  display: block;
}
.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 0;
}
.main-block {
  max-width: 900px;
  min-width: 700px;
}
.label-radio {
  color: darkgray;
  display: block;
}
.label-radio:after {
  content: " : ";
}
</style>