<template>
  <div>
    <h2 class="content-block">
      <span v-if="title">{{ title }}</span>
    </h2>

    <filter-table-unit-bisnis
      v-if="permissions.unfilteredData"
      @get-unit-bisnis="getCompany"
      @get-department="getDepartment"
    ></filter-table-unit-bisnis>

    <div
      class="content-block dx-card"
      style="justify-content: center; padding: 10px"
    >
      <div style="justify-content: center; display: flex">
        <b>Filter Keperluan RFD</b>
      </div>
      <div style="justify-content: center; display: flex">
        <DxRadioGroup
          :items="requestReasonOptions"
          value-expr="key"
          display-expr="name"
          v-model:value="valueRequestReason"
          layout="horizontal"
          @valueChanged="changeRequestReason()"
        >
        </DxRadioGroup>
      </div>
    </div>
    <br />

    <div
      class="container dx-card wide-card"
      v-if="permissions.unfilteredData && history"
    >
      <div class="date-box-container">
        <DxDateRangeBox
          style="margin-right: 10px"
          :multi-view="false"
          display-format="d / MM / yyyy"
          v-model:value="valueDate"
          :read-only="!disableBtnDate"
        ></DxDateRangeBox>
        <DxButtonx
          v-if="disableBtnDate"
          type="default"
          icon="fa fa-magnifying-glass"
          @click="searchDate"
        />
        <DxButtonx
          v-if="!disableBtnDate"
          type="danger"
          icon="fa fa-xmark"
          @click="searchDate"
        />
      </div>
    </div>

    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :search-value="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <DxSearchPanel :visible="true" />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          v-if="permissions.canVerified && verified"
          hint="verifikasi"
          icon="fa fa-check-to-slot"
          @click="popupHeadAction"
        />
        <DxButton
          v-if="permissions.canVerified && approval"
          hint="approve"
          icon="fa fa-check-to-slot"
          @click="popupAdminAction"
        />
        <DxButton
          hint="detail"
          icon="fa fa-book-open-reader"
          @click="viewDetail"
        />
        <DxButton
          v-if="permissions.unfilteredData && history"
          hint="direct"
          icon="fa fa-paper-plane"
          @click="onDirect"
        />
        <DxButton
          v-if="permissions.canUpdate"
          hint="edit"
          icon="fa fa-pen-to-square"
          @click="edit"
        />
        <DxButton
          hint="hapus"
          icon="fa fa-trash"
          @click="popupDestroy"
          v-if="permissions.canDelete"
        />
        <DxButton hint="download" icon="fa fa-download" @click="displayFile" />
      </dx-column>
      <dx-column data-field="request_reason" caption="Keperluan" />
      <dx-column
        v-if="permissions.unfilteredData"
        data-field="department_id"
        caption="Department"
        :customize-text="departmentName"
      />
      <dx-column data-field="doc_type" caption="Tipe Dokumen" />
      <dx-column data-field="doc_number" caption="Nomor Dokumen" />
      <dx-column
        data-field="doc_name"
        caption="Nama Dokumen"
        width="200"
        cell-template="docName"
      /><template #docName="{ data }">
        <div class="wrap-text">
          {{ data.value }}
        </div>
      </template>
      <dx-column
        v-if="permissions.unfilteredData"
        data-field="head_signed"
        caption="Disetujui Kepala Department"
        :customize-text="dateColumn"
      />
      <dx-column
        data-field="request_status"
        caption="Status"
        cell-template="grid-cell"
        width="100"
      />
      <template #grid-cell="{ data }">
        <div
          :class="{
            approve: data.value == 'approve',
            reject: data.value == 'reject',
            pending: data.value == 'pending',
          }"
        >
          {{ data.value }}
        </div>
      </template>

      <dx-column
        data-field="applicant_signed"
        caption="Tanggal Dibuat"
        :customize-text="dateColumn"
      />
      <dx-column
        v-if="history"
        data-field="verificator_date"
        caption="Tanggal Verifikasi"
        :customize-text="dateColumn"
      />
      <dx-column
        v-if="history && permissions.unfilteredData"
        data-field="sla"
        caption="Sla"
      />
      <!-- <dx-column data-field="files_url" caption="Link File" type="buttons">
        <DxButton hint="download" icon="file" @click="displayFile" />
      </dx-column> -->
    </dx-data-grid>

    <!-- popup verificator -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Verifikasi"
      :drag-enabled="false"
      :show-close-button="true"
      v-model:visible="popupHead"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Pastikan Permintaan Dokumen sudah dicek!</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="Approve"
              icon="check"
              type="success"
              @click="headVerif(true)"
            />
            <DxButtonx
              class="customBtn"
              text="Reject"
              icon="close"
              type="danger"
              @click="headVerif('reject')"
            />
          </div>
        </div>
      </template>
    </DxPopup>

    <!-- popup admin -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Verifikasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      v-model:visible="popupAdmin"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Pastikan Permintaan Dokumen sudah dicek!</b>
          <DxDateBox
            v-if="reqDocAdmin.request_reason == 'Perpanjang Dokumen Expired'"
            class="custom-form"
            label="Batas Waktu *"
            :input-attr="{ 'aria-label': 'Date' }"
            type="date"
            v-model:value="reqDocAdmin.end_date"
            display-format="d / MM / yyyy"
            :show-clear-button="true"
            :use-mask-behavior="true"
            :min="minDate"
          >
          </DxDateBox>
          <DxTextArea
            class="custom-form"
            label="Alasan"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="reqDocAdmin.approval_reason"
          >
          </DxTextArea>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="Approve"
              icon="check"
              type="success"
              @click="adminVerif('approve')"
            />
            <DxButtonx
              class="customBtn"
              text="Reject"
              icon="close"
              type="danger"
              @click="adminVerif('reject')"
            />
          </div>
        </div>
      </template>
    </DxPopup>

    <!-- popup detail -->
    <DxPopup
      :width="popupWidth"
      :height="popupHeight"
      :show-title="true"
      title="Detail Request For Document (RFD)"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      v-model:visible="popupDetail"
    >
      <template #content="{}">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">
            <div class="dx-fieldset">
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">Department</div>
                  <div class="dx-field-value">
                    : {{ departmentName({ value: detailData.department_id }) }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Keperluan</div>
                  <div class="dx-field-value">
                    : {{ detailData.request_reason }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tanggal Dibuat</div>
                  <div class="dx-field-value">
                    : {{ dateColumn({ value: detailData.applicant_signed }) }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Dibuat Oleh</div>
                  <div class="dx-field-value">
                    : {{ detailData.applicant_signature }}
                  </div>
                </div>
                <div
                  class="dx-field"
                  v-if="
                    detailData.request_reason == 'Buat Dokumen Baru' ||
                    detailData.request_reason == 'Perubahan Dokumen Baru'
                  "
                >
                  <div class="dx-field-label">Tanggal Verifikasi</div>
                  <div class="dx-field-value">
                    : {{ dateColumn({ value: detailData.head_signed }) }}
                  </div>
                </div>
                <div
                  class="dx-field"
                  v-if="
                    detailData.request_reason == 'Buat Dokumen Baru' ||
                    detailData.request_reason == 'Perubahan Dokumen Baru'
                  "
                >
                  <div class="dx-field-label">Mengetahui</div>
                  <div class="dx-field-value">
                    :
                    {{ detailData.head_signature }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tanggal Verifikasi Admin</div>
                  <div class="dx-field-value">
                    : {{ dateColumn({ value: detailData.verificator_date }) }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">DiTerima Oleh</div>
                  <div class="dx-field-value">
                    :
                    {{ detailData.verificator_signature }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">SLA</div>
                  <div class="dx-field-value">
                    :
                    {{ detailData.sla }}
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">Status</div>
                  <div class="dx-field-value">
                    :
                    <span
                      :class="{
                        green: detailData.request_status == 'approve',
                        red: detailData.request_status == 'reject',
                        yellow: detailData.request_status == 'pending',
                      }"
                      >{{ detailData.request_status }}</span
                    >
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Keterangan Status</div>
                  <div class="dx-field-value">
                    :
                    {{ detailData.approval_reason }}
                  </div>
                </div>
              </div>

              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">Nomor Dokumen</div>
                  <div class="dx-field-value">
                    : {{ detailData.doc_number }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Nama Dokumen</div>
                  <div class="dx-field-value">: {{ detailData.doc_name }}</div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tipe Dokumen</div>
                  <div class="dx-field-value">: {{ detailData.doc_type }}</div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tujuan</div>
                  <div class="dx-field-value">: {{ detailData.purpose }}</div>
                </div>
              </div>

              <div
                class="dx-card responsive-paddings"
                v-if="detailData.request_reason == 'Perubahan Dokumen Baru'"
              >
                <div class="dx-field">
                  <div>
                    <b style="text-align: center">Point Revisi :</b>
                  </div>
                </div>
                <div
                  class="tabel-revision"
                  style="padding: 0px; margin: 0px"
                  v-html="detailData.revision_points"
                ></div>
              </div>
            </div>
          </div>
        </DxScrollView>
      </template>
    </DxPopup>

    <!-- popup export -->
    <DxPopup
      width="50%"
      height="auto"
      :show-title="true"
      title="Konfirmasi Export RFD Verifikasi"
      :drag-enabled="false"
      :show-close-button="true"
      v-model:visible="popupExport"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <div>
            <form action="/editVerificationReport" @submit.prevent="onExport()">
              <div>Pilih tanggal verifikasi</div>
              <DxDateRangeBox
                class="custom-form"
                :multi-view="false"
                :show-clear-button="true"
                display-format="d / MM / yyyy"
                v-model:value="currentValueExport"
                ><DxValidator>
                  <DxRequiredRule message="Required" /> </DxValidator
              ></DxDateRangeBox>

              <div class="justify-center">
                <DxButtonx
                  v-if="!loading"
                  class="customBtn"
                  text="Export"
                  icon="export"
                  type="default"
                  :use-submit-behavior="true"
                />
                <DxLoadIndicator v-else class="custom-form" />
              </div>
            </form>
          </div>
        </div>
      </template>
    </DxPopup>

    <!-- popup delete -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
    
    <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
} from "devextreme-vue/data-grid";
import { DxTextArea } from "devextreme-vue/text-area";
import { DxItem } from "devextreme-vue/form";
import { DxBox } from "devextreme-vue/box";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";
import { mapGetters, mapActions } from "vuex";
import DxRadioGroup from "devextreme-vue/radio-group";
import { DxScrollView } from "devextreme-vue/scroll-view";
import DxDateRangeBox from "devextreme-vue/date-range-box";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import DxDateBox from "devextreme-vue/date-box";

import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

import CustomStore from "devextreme/data/custom_store";
import router from "@/router";
import notify from "devextreme/ui/notify";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";

export default {
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || `updated_at`,
            sort: sort || "desc",
            search: filterValue,
            department: vthis.department || "",
            request_reason: vthis.valueRequestReason || "",
          });

          return await datax;
        },
      }),
    };

    return {
      dataSource: dataSource,
      gridRef: "rfd-table",
      unitBisnis: "",
      department: "",
      valueRequestReason: "",
      valueDate: [],
      disableBtnDate: true,
      requestReasonOptions: [
        { name: "All", key: "" },
        { name: "Permintaan Dokumen", key: "Permintaan Dokumen" },
        { name: "Perubahan Dokumen Baru", key: "Perubahan Dokumen Baru" },
        { name: "Buat Dokumen Baru", key: "Buat Dokumen Baru" },
        {
          name: "Perpanjang Dokumen Expired",
          key: "Perpanjang Dokumen Expired",
        },
      ],
      pageSizes: [5, 10, 15],
      popupHead: false,
      popupAdmin: false,
      reqDocHead: {},
      reqDocAdmin: {},
      access: {},
      reason: "",
      title: "",
      popupDelete: false,
      deleteReqDoc: {},
      popupDetail: false,
      detailData: {},
      popupWidth: "60%", // width responsif
      popupHeight: "90%", // high otomatis
      popupExport: false,
      currentValueExport: [],
      loading: false,
      permissions: {
        canCreate: null,
        canUpdate: null,
        canDelete: null,
        canVerified: null,
        unfilteredData: null,
      },
      minDate: new Date(),
    };
  },
  created() {
    const permission = this.$route.meta.permission;
    // check permission
    this.permissions.canCreate = (permission & 2) > 0;
    this.permissions.canUpdate = (permission & 4) > 0;
    this.permissions.canVerified = (permission & 8) > 0;
    this.permissions.canDelete = (permission & 16) > 0;
    this.permissions.unfilteredData = (permission & 32) > 0;
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    ...mapGetters("authentication", ["getUserDetail"]),
    ...mapGetters("requestDocument", [
      "setRequestDocumentStatus",
      "setRequestDocument",
    ]),
    ...mapGetters("department", ["getAllDepartment"]),
  },
  props: {
    manageReq: {
      type: Boolean,
      default: false,
    },
    history: {
      type: Boolean,
      default: false,
    },
    verified: {
      type: Boolean,
      default: false,
    },
    approval: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["title"],
  mounted() {
    // start responsive popup detail
    window.addEventListener("resize", this.updatePopupSize);
    this.updatePopupSize();
    // end
  },
  beforeDestroy() {
    // start responsive popup detail
    window.removeEventListener("resize", this.updatePopupSize);
    // end
  },
  methods: {
    async onExport() {
      this.loading = true;

      // get RFD by verification
      const start = this.formatDate(this.currentValueExport[0]);
      const end = this.formatDate(this.currentValueExport[1]);
      const text = `?limit=999999&verificator_date=${start},${end}`;
      const rfd = await this.$store.dispatch(
        "requestDocument/getRequestDocuments",
        text
      );
      const data = rfd.data;

      if (data.length < 1) {
        notify("data tidak ditemukan", "warning", 2000);
        this.loading = false;
        return;
      } else {
        // Membuat workbook baru
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Data RFD");

        // Definisikan kolom secara dinamis
        const columns = [
          { header: "Keperluan", key: "request_reason" },
          { header: "No Dokumen", key: "doc_number" },
          {
            header: "Department",
            key: "department_id",
            transformer: (id) => this.departmentName({ value: id }),
          },
          { header: "Nama Dokumen", key: "doc_name" },
          { header: "Diajukan Oleh", key: "applicant_signature" },
          { header: "Tanggal Diterima", key: "received_date" },
          { header: "Tanggal Diverifikasi", key: "verificator_date" },
          { header: "Diverifikasi Oleh", key: "verificator_signature" },
          { header: "SLA", key: "sla" },
          { header: "Status", key: "request_status" },
        ];

        // Tambahkan header
        worksheet.columns = columns.map((col) => ({
          header: col.header,
          key: col.key,
          width: 20, // Lebar kolom default
        }));

        // Terapkan gaya untuk header
        const headerRow = worksheet.getRow(1); // Baris pertama sebagai header
        headerRow.eachCell((cell) => {
          cell.font = {
            bold: true, // Ubah font menjadi bold
          };
          cell.alignment = {
            horizontal: "center", // Teks rata tengah secara horizontal
            vertical: "middle", // Teks rata tengah secara vertikal
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        data.forEach((item) => {
          // Tambahkan data utama di baris pertama
          const mainRowData = {};
          columns.forEach(({ key, transformer }) => {
            mainRowData[key] = transformer ? transformer(item[key]) : item[key];
          });

          const mainRow = worksheet.addRow(mainRowData);
          // Menambahkan border untuk setiap cell di baris yang baru dimasukkan
          mainRow.eachCell((cell) => {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
        });

        // Menambahkan border untuk seluruh range sel (dari baris pertama hingga terakhir)
        // Dapatkan jumlah baris dan kolom yang ada
        const rowCount = worksheet.rowCount;
        const columnCount = worksheet.columns.length;

        // Menambahkan border untuk seluruh worksheet
        for (let rowIndex = 1; rowIndex <= rowCount; rowIndex++) {
          const row = worksheet.getRow(rowIndex);
          for (let colIndex = 1; colIndex <= columnCount; colIndex++) {
            const cell = row.getCell(colIndex);
            if (cell) {
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            }
          }
        }

        // export file excle
        workbook.xlsx
          .writeBuffer()
          .then((buffer) => {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              `Laporan RFD (${start})(${end}).xlsx`
            );
            // close popup
            this.popupExport = false;
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.log(e, "error");
            notify(e.message, "error", 2000);
          });
      }
    },
    formatDate(params) {
      if (!params) return "";
      const day = String(params.getDate());
      const month = String(params.getMonth() + 1);
      const year = params.getFullYear();
      return `${year}-${month}-${day}`;
    },
    searchDate() {
      if (this.valueDate.length == 0) {
        notify("filter tanggal kosong", "warning", 2000);
        return;
      }
      this.disableBtnDate = !this.disableBtnDate;
      if (this.disableBtnDate == true) {
        this.valueDate = [];
      }
      this.dataGrid.refresh();
    },
    displayFile(params) {
      if (!params.row.data.attachment) {
        notify("Lampiran tidak tersedia", "warning", 2000);
        return;
      }
      let fileName = params.row.data.attachment;
      let parts = fileName?.split(".");
      let extension = parts[parts?.length - 1];
      console.log(extension);
      if (extension == "pdf") {
        this.$store.dispatch(
          "requestDocument/setRequestDocument",
          params.row.data
        );
        router.push(`/attachment-preview/${params.row.key}`);
        return;
      }
      window.open(params.row.data.attachment, "_blank");
    },
    updatePopupSize() {
      this.popupWidth = window.innerWidth <= 600 ? "95%" : "60%";
    },
    viewDetail(params) {
      this.popupDetail = true;
      this.detailData = params.row.data;
    },
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "-";
      }
      return `${depart.company_name} | ${depart.department_name}`;
    },
    confirmDestroy() {
      this.$store
        .dispatch("requestDocument/destroyRequestDocument", this.deleteReqDoc)
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;

      this.deleteReqDoc = params.row.data;
    },
    async headVerif(params) {
      const now = new Date();
      if (params == "reject") {
        this.reqDocHead.request_status = params;
      } else {
        this.reqDocHead.head_signed = now;
      }

      this.reqDocHead.received_date = now;
      this.reqDocHead.head_signature = this.getUserDetail.full_name;
      this.reqDocHead._method = "PUT";

      this.popupHead = false;
      this.updateReqDoc(this.reqDocHead);
    },
    popupHeadAction(params) {
      this.reqDocHead = params.row.data;
      this.popupHead = true;
    },
    async onDirect(params) {
      const data = params.row.data;
      if (data.request_status == "approve") {
        const isCreateDoc =
          data.request_reason == "Buat Dokumen Baru" &&
          data.coordinator_signed == null;
        const isChangeDoc =
          data.request_reason == "Perubahan Dokumen Baru" &&
          data.coordinator_signed == null;

        if (isCreateDoc) {
          router.push(`/document-list/form/buat-dokumen-baru/${data.id}`);
          this.$store.dispatch("requestDocument/setRequestDocument", data);
        } else if (isChangeDoc) {
          this.$store.dispatch("requestDocument/setRequestDocument", data);
          router.push(`/document-file/form/perubahan-dokumen-baru/${data.id}`);
        } else {
          notify("Permintaan dokumen sudah berhasil !", "success", 2000);
        }
      } else {
        notify("Status reject !", "error", 2000);
      }
    },
    async adminVerif(params) {
      if (
        params == "approve" &&
        this.reqDocAdmin.request_reason == "Perpanjang Dokumen Expired"
      ) {
        if (!this.reqDocAdmin.end_date) {
          return notify("Batas waktu harus diisi !", "warning", 2000);
        }
        this.reqDocAdmin.attachment = null;
      }
      this.reqDocAdmin.request_status = params;
      const now = new Date();

      this.reqDocAdmin.verificator_id = this.getUserDetail.user_id;
      this.reqDocAdmin.verificator_signature = this.getUserDetail.full_name;
      this.reqDocAdmin.verificator_date = now;
      this.reqDocAdmin._method = "PUT";

      const sla = new Date(
        new Date(this.reqDocAdmin.received_date).getTime() +
          15 * 24 * 60 * 60 * 1000
      );

      if (new Date().getTime() <= sla.getTime()) {
        this.reqDocAdmin.sla = "on time";
      } else {
        this.reqDocAdmin.sla = "delay";
      }
      this.popupAdmin = false;
      console.log(this.reqDocAdmin, "TESTSTSTS");

      this.updateReqDoc(this.reqDocAdmin);
    },
    popupAdminAction(params) {
      this.reqDocAdmin = params.row.data;
      this.popupAdmin = true;
    },
    async updateReqDoc(params) {
      const vThis = this;
      this.$store
        .dispatch("requestDocument/updateRequestDocument", params)
        .then((res) => {
          const now = new Date();
          const data = res.data.data;
          if (
            data.request_status == "approve" &&
            data.request_reason == "Permintaan Dokumen"
          ) {
            this.access.document_id = data.document_id;
            this.access.document_name = data.doc_number;
            this.access.user_id = data.applicant_id;
            this.access.fullname = data.applicant_signature;
            this.access.expired_date = new Date(now.setDate(now.getDate() + 7));
            this.postDocAccessRight(this.access);
          } else if (
            data.request_status == "approve" &&
            data.request_reason == "Perpanjang Dokumen Expired"
          ) {
            vThis.updateDocExpired(
              vThis.reqDocAdmin.document_id,
              vThis.reqDocAdmin.attachment_name,
              vThis.reqDocAdmin.end_date
            );
          }
          notify("Verifikasi Berhasil", "success", 2000);
          // belum selesai
          vThis.dataGrid.refresh();
        });
    },
    async updateDocExpired(params, attachments, dateExpired) {
      const docFiles = await this.$store.dispatch(
        "docFile/getDocFiles",
        `?document=${params}&is_active=true`
      );

      if (docFiles.data && docFiles.data.length > 0) {
        // Ambil data yang diperlukan dari docFiles untuk editDocFile
        const editDocFile = docFiles.data[0];
        editDocFile._method = "PUT";
        editDocFile.upload_date = new Date();
        editDocFile.end_date = dateExpired;
        editDocFile.attachments = attachments;

        // Lakukan dispatch untuk "docFile/attachmentToDocFile" dengan data editDocFile
        await this.$store.dispatch("docFile/attachmentToDocFile", editDocFile);
      } else {
        console.error("No documents found to edit.");
      }
    },
    async postDocAccessRight(params) {
      await this.$store.dispatch("docAccessRight/postDocAccess", params);
    },
    async edit(params) {
      this.$store.dispatch(
        "requestDocument/setRequestDocument",
        params.row.data
      );
      router.push(`/request-document/form/edit/${params.row.key}`);
    },
    dateColumn(cellInfo) {
      if (!cellInfo.value) {
        return "-";
      }
      const date = new Date(cellInfo.value);
      const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const mounth = parseInt(date.getMonth()) + 1;
      const mm = mounth < 10 ? "0" + mounth : mounth;

      return dd + "/" + mm + "/" + date.getFullYear();
    },
    changeRequestReason() {
      this.dataGrid.refresh();
    },
    getCompany(value) {
      this.unitBisnis = value;
      this.dataGrid.refresh();
    },
    async getDepartment(value) {
      this.department = value;

      const text = `?department=${value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );

      if (value) {
        this.title =
          // "Daftar Permintaan Dokumen " + depart.data[0].department_name;
          this.$emit("title", depart.data[0].department_name);
      } else {
        // this.title = "";
        this.$emit("title", value);
      }

      this.dataGrid.refresh();
    },

    onToolbarPreparing(e) {
      const vThis = this;
      if (this.permissions.unfilteredData && this.history) {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
            icon: "export",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              vThis.popupExport = true;
            },
          },
        });
      }
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
      if (this.permissions.canCreate) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              router.push("/request-document/form/add");
            },
          },
        });
      }
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      // search verif date
      if (this.valueDate.length > 0) {
        params.verificator_date = `${this.formatDate(
          this.valueDate[0]
        )},${this.formatDate(this.valueDate[1])}`;
      }

      if (this.permissions.unfilteredData == false) {
        if (this.manageReq) {
          params.request_status = "pending";
          params.applicant = this.getUserDetail.user_id;
          this.title =
            "Daftar Permintaan Dokumen " + this.getUserDetail.full_name;
          console.log("KELOLA REQ USER");
        } else if (this.history) {
          params.department = this.getUserDetail.department_id;
          params.history = "pending";
          this.title =
            "Riwayat Permintaan Dokumen " + this.getUserDetail.department_name;
          console.log("HISTORY USER");
        } else if (this.verified) {
          params.where_null = "head_signed";
          params.request_status = "pending";
          params.department = this.getUserDetail.department_id;
          this.title =
            "Menunggu Persetujuan Head " + this.getUserDetail.department_name;
          console.log("VERIFIKASI HEAD");
        }
      } else {
        if (this.approval) {
          params.request_status = "pending";
          params.not_null = "head_signed";
          console.log("ADMIN APPROVE");
        } else if (this.history) {
          params.history = "pending";
          params.not_null = "head_signed";
          console.log("ADMIN RIWAYAT");
        }
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "requestDocument/getRequestDocuments",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxTextArea,
    DxSearchPanel,
    DxButton,
    DxBox,
    DxSelectBox,
    FilterTableUnitBisnis,
    DxPopup,
    DxButtonx,
    DxRadioGroup,
    DxScrollView,
    DxDateRangeBox,
    Workbook,
    saveAs,
    DxValidator,
    DxRequiredRule,
    DxLoadIndicator,
    DxDateBox,
  },
};
</script>

<style scoped>
.link-icon {
  text-decoration: none;
  font-size: 18px;
}
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}

.approve {
  background-color: green;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
.reject {
  background-color: red;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
.pending {
  background-color: yellow;
  color: black;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
.green {
  background-color: #8bc34a;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.red {
  background-color: red;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.yellow {
  background-color: #ffff00;
  color: black;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.dx-field-label {
  white-space: normal;
}
.container {
  display: flex; /* Gunakan Flexbox untuk tata letak baris */
  justify-content: space-between; /* Menyebarkan elemen antara kiri dan kanan */
  align-items: flex-end; /* Menyelaraskan elemen secara vertikal di tengah */
}

.date-box-container {
  display: flex; /* Membuat elemen dalam container menjadi baris */
  align-items: center; /* Menyelaraskan elemen secara vertikal di tengah */
  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 20px;
}

.tabel-revision :deep(table) {
  width: 100%;
  border-collapse: collapse;
}
/* :deep() untuk mengatasi style diload (v-html) */

.tabel-revision :deep(th),
.tabel-revision :deep(td) {
  border: 1px solid black !important; /* Garis tepi */
}

.tabel-revision :deep(th) {
  background-color: #f2f2f2; /* Warna latar belakang header */
  text-align: center;
}
.wrap-text {
  white-space: normal;
  overflow-wrap: break-word;
}
</style>