<template>
  <div>
    <h2 class="content-block">
      {{ getTemplateDocStatus == "Add" ? "Membuat" : "Edit" }} Template Dokumen
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formTemplateDocs @get-template-doc="submit"></formTemplateDocs>
  </div>
</template>
    <script>
import formTemplateDocs from "@/components/documentManagement/formTemplateDocs.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formTemplateDocs,
    DxButton,
  },
  computed: {
    ...mapGetters("templateDocs", ["getTemplateDocStatus"]),
  },
  methods: {
    async submit(value) {
      if (this.getTemplateDocStatus == "Add") {
        this.$store
          .dispatch("templateDocs/postTemplateDoc", value)
          .then((res) => {
            router.push(`/super-admin/template-docs/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getTemplateDocStatus == "Edit") {
        value._method = "PUT";
        this.$store
          .dispatch("templateDocs/editTemplateDoc", value)
          .then((res) => {
            router.push(`/super-admin/template-docs/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>