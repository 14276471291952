<template>
  <div>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :search-value="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <DxSearchPanel :visible="true" />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          hint="verif"
          icon="fieldchooser"
          @click="verif"
          v-if="verifikasi"
        />
        <DxButton hint="active" icon="close" @click="verif" v-if="active" />
        <DxButton
          hint="not active"
          icon="check"
          @click="verif"
          v-if="noActive"
        />
        <DxButton
          hint="hapus"
          icon="trash"
          @click="popupDestroy"
          v-if="role == 'suAdmin'"
        />
      </dx-column>
      <dx-column data-field="username" caption="Username" />
      <dx-column data-field="user_group_detail.name" caption="Level" />
      <dx-column data-field="is_active" caption="Status" />
      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{ data: account }">
        <h3>Profil Pengguna :</h3>
        <table-employee :account="account.data" />
      </template>
    </dx-data-grid>

    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Verifikasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      v-model:visible="popupAdmin"
    >
      <template #content="{}">
        <b>Pastikan Profil Akun sudah dicek!</b>
        <div class="popup-property-details">
          <div v-if="verifikasi">
            <DxSelectBox
              class="custom-form"
              :data-source="rolesOptions"
              label="Pilih Role"
              value-expr="id"
              display-expr="name"
              v-model="account.roles"
            >
            </DxSelectBox>
            <div class="label-radio">Status Akun</div>
            <DxRadioGroup
              :items="activeOptions"
              value-expr="id"
              display-expr="name"
              v-model:value="account.is_active"
              layout="horizontal"
            >
            </DxRadioGroup>
            <div class="justify-center">
              <DxButtonx
                class="customBtn"
                text="Simpan"
                icon="save"
                type="success"
                @click="handleSubmit()"
              />
            </div>
          </div>

          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="dimatikan"
              icon="close"
              type="danger"
              @click="adminVerif(false)"
              v-if="active"
            />
            <DxButtonx
              class="customBtn"
              text="diaktifkan"
              icon="check"
              type="success"
              @click="adminVerif(true)"
              v-if="noActive"
            />
          </div>
        </div>
      </template>
    </DxPopup>
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
    <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import router from "@/router";
import tableEmployee from "@/components/master/tableEmployee.vue";
import notify from "devextreme/ui/notify";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import DxRadioGroup from "devextreme-vue/radio-group";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    tableEmployee,
    DxButtonx,
    DxSelectBox,
    DxValidator,
    DxRequiredRule,
    DxRadioGroup,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            search: filterValue,
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "company-table",
      paramsEdit: "",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      btnAdd: null,
      popupAdd: false,
      popupAdmin: false,
      rolesOptions: [
        { id: 1, name: "Staff" },
        { id: 2, name: "Head" },
      ],
      activeOptions: [
        { id: 1, name: "Setuju" },
        { id: 0, name: "Tolak" },
      ],
      account: {},
      popupDelete: false,
      deleteAccount: {},
    };
  },
  mounted() {},
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    noActive: {
      type: Boolean,
      default: false,
    },
    verifikasi: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
    },
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
  },
  methods: {
    handleSubmit() {
      if (this.account.roles == null || this.account.is_active == null) {
        notify("form verifikasi harus diisi", "error", 2000);
        return;
      }

      if (this.account.roles == 2) {
        this.account.user_group = "f47ac10b-58cc-4372-a567-0e02b2c3d479";
      } else {
        this.account.user_group = "7ae06164-05b0-4834-a17c-d033c1581d9d";
      }

      this.updateAccount(this.account);
      this.account = "";
      this.popupAdmin = false;
    },
    confirmDestroy() {
      this.$store
        .dispatch("authentication/destroyAccount", this.deleteAccount)
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;

      this.deleteAccount = params.row.data;
    },
    updateStatus(params) {
      this.account.is_active = params;
      this.updateAccount(this.account);
      this.popupAdmin = false;
    },
    adminVerif(params) {
      this.account.is_active = params;
      this.updateAccount(this.account);
      this.popupAdmin = false;
    },
    updateAccount(params) {
      const vthis = this;
      this.$store
        .dispatch("authentication/updateAccounts", params)
        .then((res) => {
          vthis.dataGrid.refresh();
          notify("Verifikasi Berhasil", "success", 2000);
        });
    },
    verif(params) {
      this.account = params.row.data;
      this.popupAdmin = true;
    },
    onToolbarPreparing(e) {
      const vThis = this;
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      if (this.active) {
        params.is_active = true;
      } else if (this.noActive) {
        params.is_active = false;
      } else if (this.verifikasi) {
        params.where_null = "is_active";
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "authentication/getAccounts",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };

      return await datax;
    },
  },
};
</script>
    <style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>