<template>
  <div>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          hint="edit"
          icon="fa fa-pen"
          @click="edit"
          v-if="permissionProps.canUpdate"
        />
        <DxButton
          hint="hapus"
          icon="fa fa-trash"
          @click="popupDestroy"
          v-if="permissionProps.canDelete"
        />
      </dx-column>
      <dx-column
        data-field="department_id"
        caption="Department Name"
        :customize-text="departmentName"
      />
      <dx-column data-field="code" caption="Kode Department" />
    </dx-data-grid>
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
    <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";

import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import department from "@/store/modules/department";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    DxButtonx,
  },
  data() {
    return {
      gridRef: "department-table",
      dataSource: [],
      pageSizes: [5, 10, 15],
      btnAdd: null,
      popupDelete: false,
      deleteData: {},
    };
  },
  mounted() {
    this.dataSource = this.departmentProps.department;
    console.log(this.departmentProps, "TEST");
  },
  props: ["departmentProps", "permissionProps"],
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    ...mapGetters("department", ["getAllDepartment"]),
  },
  methods: {
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "-";
      }
      return `${depart.department_name}`;
    },
    confirmDestroy() {
      const vThis = this;
      this.$store
        .dispatch("department/destroyCodeDepartment", this.deleteData)
        .then((res) => {
          notify("delete success", "success", 2000);
          vThis.dataSource = vThis.dataSource.filter(
            (item) => item.id !== vThis.deleteData.id
          );
          vThis.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      console.log("berhasil");
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;
      this.deleteData = params.row.data;
    },
    edit(params) {
      this.$store.dispatch("department/setCodeDepartment", params.row.data);
      router.push(
        `/code-department/form/edit/${params.row.data.department_id}`
      );
    },
    onToolbarPreparing(e) {
      const vThis = this;
      if (this.permissionProps.canCreate) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              router.push(
                `/code-department/form/add/${vThis.departmentProps.company_id}`
              );
            },
          },
        });
      }
    },
  },
};
</script>
    <style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
</style>