<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addCodeDepartment" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in codeDepartment" :key="index">
          <!-- <DxSelectBox
            :read-only="readOnly"
            class="custom-form"
            :data-source="unitBisnisOptions"
            label="Pilih Unit Bisnis"
            value-expr="id"
            display-expr="name"
            v-model="form.id"
            @valueChanged="changeUnitBisnis"
          >
          </DxSelectBox> -->
          <DxSelectBox
            :read-only="readOnlyDepartment"
            class="custom-form"
            :data-source="departmentOptions"
            label="Pilih Department"
            value-expr="department_id"
            display-expr="department_name"
            v-model.value="form.department_id"
          >
          </DxSelectBox>

          <DxTextBox
            class="custom-form"
            label="Kode"
            v-model:value="form.code"
            :is-required="true"
            ><DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextBox>
          <DxButton
            v-if="codeDepartment.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>
        <div v-if="!loading" class="custom-form">
          <DxButton
            v-if="paramsRoute.condition == 'add' && codeDepartment.length < 10"
            class="customBtn"
            text="Form"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            class="customBtn"
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else class="custom-form" />
      </form>
    </div>
  </div>
</template>
        
        <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      unitBisnisOptions: [],
      departmentOptions: [],
      codeDepartment: [
        {
          company_id: "",
          department_id: "",
          code: "",
        },
      ],
      paramsRoute: this.$route.params,
      readOnly: false,
      readOnlyDepartment: false,
    };
  },
  computed: {
    ...mapGetters("department", ["getAllDepartment", "getCodeDepartment"]),
    ...mapGetters("unitBisnis", ["getCodeUnit", "getAllUnitBisnis"]),
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      this.readOnlyDepartment = true;
      if (this.getCodeUnit) {
        this.getDepartment(this.getCodeUnit.company_id);
        this.codeDepartment[0] = this.getCodeUnit;
      } else {
        this.loadCodeDepartment(this.paramsRoute.id);
      }
    } else {
      this.getDepartment(this.paramsRoute.id);
    }
  },
  emits: ["get-department-code"],
  methods: {
    async loadCodeDepartment(params) {
      try {
        const getCode = await this.$store.dispatch(
          "department/getDepartments",
          `/${params}`
        );
        console.log(getCode, "TEST");
        this.getDepartment(getCode.data.company_id);

        this.codeDepartment[0] = getCode.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.codeDepartment.push({
        company_id: "",
        department_id: "",
        code: "",
      });
    },
    removeForm(index) {
      this.codeDepartment.splice(index, 1);
    },
    async getDepartment(params) {
      const depart = this.getAllDepartment?.filter(
        (e) => e.company_id == params
      );
      console.log(params);

      this.departmentOptions = depart;
    },
    async handleSubmit(e) {
      this.loading = true;
      this.$emit("get-department-code", this.codeDepartment);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    FilterTableUnitBisnis,
    DxLoadIndicator,
  },
};
</script>
        
        <style scoped>
.customBtn {
  margin: 1%;
}
.custom-form {
  margin-top: 10px;
}
</style>