<template>
  <div>
    <div class="content-block header">
      <h2 class="group"><i class="fa-solid fa-house-user"></i> Home</h2>
      <div class="group">
        <DxButton
          style="margin-top: 10px"
          text="Refresh"
          hint="Refresh"
          icon="fa fa-arrows-rotate"
          @click="refresh"
        />
      </div>
    </div>

    <div class="container-message">
      <h1 class="thank-you-message">
        Halo, {{ getUserDetail.full_name }}! Login berhasil, Kami senang Anda
        kembali.
      </h1>
    </div>

    <div class="content-block">
      <div class="card-grid">
        <div class="card-item" v-for="(card, index) in dashboard" :key="index">
          <div :width="'100%'" class="dx-card card">
            <div class="card-content">
              <div class="card-left">
                <i :class="card.dashboard.icon" class="card-icon"></i>
                <!-- <i :class="[card.icon]" class="card-icon"></i> -->
              </div>
              <div class="card-right">
                <div class="data-count">
                  {{ vDashboard[card.dashboard.data_name] }}
                </div>
                <div class="dashboard-name">{{ card.dashboard.name }}</div>
                <div class="card-button">
                  <DxButton
                    height="20px"
                    text="detail"
                    :type="card.dashboard.color"
                    @click="direct(card.dashboard.path)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- popup welcome -->
    <!-- <DxPopup
      width="50%"
      height="auto"
      :show-title="true"
      title="Informasi"
      :drag-enabled="false"
      :hide-on-outside-click="false"
      v-model:visible="popupWelcome"
    >
      <DxPosition
        at="top"
        my="center"
        v-model:of="positionOf"
        collision="fit"
      />
      <template #content="{}">
        <div class="popup-property-details">
          <h5>
            Selamat Datang Anda Login Sebagai
            <span class="text2">{{ getUser.group_name }}</span> &nbsp;
            <i class="fa-regular fa-face-smile" style="color: red"></i>
            <i class="fa-regular fa-hand-peace" style="color: red"></i>
          </h5>
          <DxButton
            class="customBtn"
            text="OK"
            type="default"
            @click="refresh"
          />
        </div>
      </template>
    </DxPopup> -->
  </div>
</template>
<script>
import DxButton from "devextreme-vue/button";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import router from "@/router";
import Api from "@/services/Api";
import CryptoJS from "crypto-js";
import { DxTextBox } from "devextreme-vue/text-box";

export default {
  data() {
    return {
      vDashboard: {
        // rfdVerified: "",
        // verifDocVerified: "",
        // capaVerified: "",
        // rfdAdmin: "",
        // expDocAdmin: "",
        // verifRegAdmin: "",
        // verifAccountAdmin: "",
        // auditPlanAdmin: "",
        // meetingPlanAdmin: "",
        // auditAdmin: "",
        // verifImprovAdmin: "",
        // docAccessUser: "",
        // docExpiredUser: "",
        // auditUser: "",
        // auditPlanUser: "",
        // meetingPlanUser: "",
        // auditorUser: "",
        // auditeeUser: "",
      },
      // popupWelcome: true,
      positionOf: "",
      interval: null,
      isPageVisible: true,
      loading: true,
      dashboard: [],
      validateDashboard: [],
    };
  },
  computed: {
    ...mapGetters("authentication", [
      "getUserDetail",
      "getUser",
      "getPopUpHome",
    ]),
    checkStorage() {
      if (
        localStorage.getItem("m_dpt") &&
        localStorage.getItem("m_sec") &&
        localStorage.getItem("m_unt") &&
        localStorage.getItem("m_bran")
      ) {
        return true;
      }
      return false;
    },
  },
  props: {
    roles: {
      type: String,
    },
  },
  created() {
    this.getDashboard();
  },
  mounted() {
    // this.checkDashboard();
    // if (this.getPopUpHome != null) {
    //   this.popupWelcome = false;
    // }
    if (!this.checkStorage) {
      this.getBranchs();
      this.getSections();
      this.getDepartments();
      this.getUnitBinis();
    }

    // refresh otomatis
    // this.interval = setInterval(() => {
    //   if (this.isPageVisible) {
    //     this.refresh();
    //   }
    // }, 120000);
    // // Menangani event visibility change
    // document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  // beforeDestroy() {
  //   // Membersihkan interval saat komponen dihapus
  //   if (this.interval) {
  //     clearInterval(this.interval);
  //   }
  //   document.removeEventListener(
  //     "visibilitychange",
  //     this.handleVisibilityChange
  //   );
  // },
  methods: {
    // checkDashboard() {
    //   this.dashboard.forEach((items) => {
    //     if (items.dashboard.data_name == "docAccessUser") {
    //       this.getDocAccessUser();
    //     } else if (items.dashboard.data_name == "docExpiredUser") {
    //       this.getDocExpiredUser();
    //     } else if (items.dashboard.data_name == "auditUser") {
    //       this.getAuditUser();
    //     } else if (items.dashboard.data_name == "auditPlanUser") {
    //       this.getAuditPlanUser();
    //     } else if (items.dashboard.data_name == "meetingPlanUser") {
    //       this.getMeetingPlanUser();
    //     } else if (items.dashboard.data_name == "rfdVerified") {
    //       this.getRfdVerified();
    //     } else if (items.dashboard.data_name == "verifDocVerified") {
    //       this.getVerifDocVerified();
    //     } else if (items.dashboard.data_name == "rfdAdmin") {
    //       this.getRfdAdmin();
    //     } else if (items.dashboard.data_name == "expDocAdmin") {
    //       this.getExpDocAdmin();
    //     } else if (items.dashboard.data_name == "verifRegAdmin") {
    //       this.getVerifRegAdmin();
    //     } else if (items.dashboard.data_name == "verifAccountAdmin") {
    //       this.getVerifAccountAdmin();
    //     } else if (items.dashboard.data_name == "auditPlanAdmin") {
    //       this.getAuditPlanAdmin();
    //     } else if (items.dashboard.data_name == "meetingPlanAdmin") {
    //       this.getMeetingPlanAdmin();
    //     } else if (items.dashboard.data_name == "auditorUser") {
    //       this.getCapa(
    //         `?auditor=${this.getUserDetail.user_id}&capa_status=Checking`,
    //         "auditor"
    //       );
    //     } else if (items.dashboard.data_name == "auditeeUser") {
    //       this.getCapa(`?my_auditee=${this.getUserDetail.user_id}`, "auditee");
    //     } else if (items.dashboard.data_name == "capaVerified") {
    //       this.getCapa(
    //         `?department_auditee=${this.getUserDetail.department_id}&capa_status=Pending`,
    //         "verified"
    //       );
    //     } else if (items.dashboard.data_name == "auditAdmin") {
    //       this.getAuditAdmin();
    //     } else if (items.dashboard.data_name == "verifImprovAdmin") {
    //       this.getVerifImprovAdmin();
    //     } else {
    //       console.log(this.dashboard);
    //     }
    //   });
    // },
    // async getCapa(params, type) {
    //   const getCapa = await this.$store.dispatch("capa/getCapa", params);

    //   if (type == "verified") {
    //     this.vDashboard.capaVerified = getCapa.paginate.total.toString();
    //   } else if (type == "auditee") {
    //     this.vDashboard.auditeeUser = getCapa.paginate.total.toString();
    //   } else {
    //     this.vDashboard.auditorUser = getCapa.paginate.total.toString();
    //   }
    // },
    // async getDocAccessUser() {
    //   const paramsDocAccessUser = `?access_by_user=${this.getUserDetail.user_id}`;

    //   const userDocAccess = await this.$store.dispatch(
    //     "document/getDocumentAccess",
    //     paramsDocAccessUser
    //   );
    //   this.vDashboard.docAccessUser = userDocAccess.paginate.total.toString();
    // },
    // async getDocExpiredUser() {
    //   const paramsDocExpiredUser = `?department=${this.getUserDetail.department_id}`;

    //   const userDocExpired = await this.$store.dispatch(
    //     "docFile/getDocNotif",
    //     paramsDocExpiredUser
    //   );
    //   this.vDashboard.docExpiredUser = userDocExpired.paginate.total;
    // },
    // async getAuditUser() {
    //   const paramsAuditUser = `?auditee=${this.getUserDetail.department_id}&branch=${this.getUserDetail.location_id}`;

    //   const userAudit = await this.$store.dispatch(
    //     "auditReport/getAuditReport",
    //     paramsAuditUser
    //   );
    //   this.vDashboard.auditUser = userAudit.paginate.total;
    // },
    // async getAuditPlanUser() {
    //   const paramsAuditPlanUser = `?notif_audit=${
    //     this.getUserDetail.department_id
    //   }&section=${this.getUserDetail.section_id || ""}`;

    //   const userAuditPlan = await this.$store.dispatch(
    //     "auditPlan/getAuditPlan",
    //     paramsAuditPlanUser
    //   );
    //   this.vDashboard.auditPlanUser = userAuditPlan.paginate.total;
    // },
    // async getMeetingPlanUser() {
    //   const paramsMeetPlanUser = `?notif_meeting=${this.getUserDetail.department_id}&agenda_type=Agenda Meeting`;
    //   const userMeetPlan = await this.$store.dispatch(
    //     "auditPlan/getAuditPlan",
    //     paramsMeetPlanUser
    //   );

    //   this.vDashboard.meetingPlanUser = userMeetPlan.paginate.total;
    // },
    // async getRfdVerified() {
    //   const paramsRfd = `?department=${this.getUserDetail.department_id}&where_null=head_signed&request_status=pending`;
    //   const headRfd = await this.$store.dispatch(
    //     "requestDocument/getRequestDocuments",
    //     paramsRfd
    //   );
    //   this.vDashboard.rfdVerified = headRfd.paginate.total;
    // },
    // async getVerifDocVerified() {
    //   const paramsDoc = `?approval_null=${this.getUserDetail.department_id}`;
    //   const doc = await this.$store.dispatch(
    //     "document/getWithDocApproval",
    //     paramsDoc
    //   );
    //   this.vDashboard.verifDocVerified = doc.paginate.total;
    // },
    // async getRfdAdmin() {
    //   const paramsRfd = `?request_status=pending&not_null=head_signed`;
    //   const adminRfd = await this.$store.dispatch(
    //     "requestDocument/getRequestDocuments",
    //     paramsRfd
    //   );
    //   this.vDashboard.rfdAdmin = adminRfd.paginate.total;
    // },
    // async getVerifAccountAdmin() {
    //   const paramsAkun = `?where_null=is_active`;
    //   const adminAkun = await this.$store.dispatch(
    //     "authentication/getAccounts",
    //     paramsAkun
    //   );
    //   this.vDashboard.verifAccountAdmin = adminAkun.paginate.total;
    // },
    // async getExpDocAdmin() {
    //   const adminExpDoc = await this.$store.dispatch(
    //     "docFile/getDocNotif",
    //     "?"
    //   );
    //   this.vDashboard.expDocAdmin = adminExpDoc.paginate.total;
    // },
    // async getVerifRegAdmin() {
    //   const paramsReg = "?impl_null=verified";
    //   const adminVerifReg = await this.$store.dispatch(
    //     "regulationList/getRegulationLists",
    //     paramsReg
    //   );
    //   this.vDashboard.verifRegAdmin = adminVerifReg.paginate.total;
    // },
    // async getAuditPlanAdmin() {
    //   const paramsAudPlan = `?notif_agenda=true&agenda_type=Agenda Audit&status=false`;
    //   const adminAudPlan = await this.$store.dispatch(
    //     "auditPlan/getAuditPlan",
    //     paramsAudPlan
    //   );
    //   this.vDashboard.auditPlanAdmin = adminAudPlan.paginate.total;
    // },
    // async getMeetingPlanAdmin() {
    //   const paramsMeetPlan = `?notif_agenda=true&agenda_type=Agenda Meeting`;
    //   const adminMeetPlan = await this.$store.dispatch(
    //     "auditPlan/getAuditPlan",
    //     paramsMeetPlan
    //   );
    //   this.vDashboard.meetingPlanAdmin = adminMeetPlan.paginate.total;
    // },
    // async getAuditAdmin() {
    //   const paramsAuditAdmin = `?status=Pending`;

    //   const adminAudit = await this.$store.dispatch(
    //     "auditReport/getAuditReport",
    //     paramsAuditAdmin
    //   );
    //   this.vDashboard.auditAdmin = adminAudit.paginate.total;
    // },
    // async getVerifImprovAdmin() {
    //   const paramsVerifImprovAdmin = `?status=Checking`;

    //   const adminVerifImprov = await this.$store.dispatch(
    //     "improvementTheme/getImprovementTheme",
    //     paramsVerifImprovAdmin
    //   );
    //   this.vDashboard.verifImprovAdmin = adminVerifImprov.paginate.total;
    // },
    async getDashboard() {
      const dash = await this.$store.dispatch(
        "userDashboardAccess/getDashboards",
        ""
      );
      // const dash = localStorage.getItem("per_dash")
      //   ? JSON.parse(
      //       CryptoJS.AES.decrypt(
      //         localStorage.getItem("per_dash"),
      //         "secretKey"
      //       ).toString(CryptoJS.enc.Utf8)
      //     )
      //   : null;

      // dash.sort((a, b) => a.dashboard.order - b.dashboard.order);

      // console.log(dash, "INI DASHBOARD");

      let permission_dashboard = dash.dashboard_detail.permission_dashboard;
      permission_dashboard.sort(
        (a, b) => a.dashboard.order - b.dashboard.order
      );

      this.dashboard = permission_dashboard;
      this.vDashboard = dash.count_dashboard;
    },
    handleVisibilityChange() {
      this.isPageVisible = !document.hidden;
    },
    async getBranchs() {
      await this.$store.dispatch("branch/getAllBranch", "?limit=9999");
    },
    async getSections() {
      await this.$store.dispatch("section/getAllSection", "?");
    },
    async getDepartments() {
      await this.$store.dispatch("department/getAllDepartment", "?");
    },
    async getUnitBinis() {
      await this.$store.dispatch("unitBisnis/getAllUnitBisnis", "?");
    },
    refresh() {
      // if (!this.popupWelcome) {
      this.getDashboard();
      // this.checkDashboard();
      this.getBranchs();
      this.getSections();
      this.getDepartments();
      this.getUnitBinis();
      // }
      // this.$store.dispatch("authentication/setPopUpHome", false);
      // this.popupWelcome = false;
    },
    direct(value) {
      router.push(value);
    },
  },
  components: {
    DxButton,
    DxPopup,
    DxPosition,
    DxLoadIndicator,
    DxTextBox,
  },
};
</script>
  
  <style lang="scss" scoped>
.btn-refresh {
  margin-top: 10px;
}
.header {
  justify-content: space-between;
  display: flex;
  margin-bottom: 25px;
  margin-top: 10px;
}
.group {
  float: left;
  margin: 10px;
}
.customBtn {
  margin: 1%;
}
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
  width: 22%;
  margin: 10px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 17px;
}

.vue-logo {
  width: 180px;
  height: 62px;
}

.plus {
  margin: 20px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}
.dx-button.dx-button-warning {
  background-color: hsl(60, 100%, 50%);
}

/* BARU */
.card-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.card-item {
  flex: 1 1 calc(25% - 20px);
  box-sizing: border-box;
  min-width: 200px;
}

.card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.card-content {
  display: flex;
  width: 100%;
}

.card-left {
  font-size: 25px;
  color: #3498db;
  margin-right: 10px;
}

.card-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.data-count {
  font-size: 20px;
  font-weight: bold;
}

.dashboard-name {
  font-size: 16px;
  margin-top: 5px;
}

.card-button {
  margin-top: 5px;
}

.card-button .btn {
  background-color: #3498db;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
}

.card-button .btn:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .card-item {
    flex: 1 1 calc(50% - 20px);
    min-width: 200px;
  }
}

@media (max-width: 480px) {
  .card-item {
    flex: 1 1 calc(33% - 20px);
    min-width: 200px;
  }
}

/* Container utama */
.container-message {
  text-align: center;
  color: grey;
}

/* Ucapan Terima Kasih dengan animasi */
.thank-you-message {
  font-size: 1.5rem;
  font-weight: bold;
  animation: moveMessage 15s ease-in-out infinite;
}

/* Animasi untuk ucapan terima kasih */
@keyframes moveMessage {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
</style>
  