<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addPotentialRisk" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in potentialRisk" :key="index">
          <DxSelectBox
            class="custom-form"
            :data-source="picOption"
            label="Pilih PIC"
            value-expr="id"
            display-expr="pic"
            :is-required="true"
            v-model="form.pic_id"
            :search-enabled="true"
            item-template="item"
            :read-only="readOnly"
          >
            <template #item="{ data: item }">
              <div class="custom-item">
                <div class="product-name">
                  <b>{{ item.pic }}</b>
                  <span>
                    / Lingkup Aktivitas : {{ item.activity.activity }}</span
                  >
                  <span>
                    / Proses :
                    {{ item.activity.process.process_name }}</span
                  >
                  <span>
                    / Clausul :
                    {{ item.activity.process.checklist.clausul_relevan }} ({{
                      departmentName({
                        value: item.activity.process.checklist.department_id,
                      })
                    }})</span
                  >
                </div>
              </div>
            </template>
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>

          <DxTextArea
            class="custom-form"
            label="Potensi Risiko"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="form.potential"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextArea>

          <DxSelectBox
            class="custom-form"
            :data-source="riskLevelOptions"
            label="Pilih Risk Level"
            value-expr="name"
            display-expr="name"
            :is-required="true"
            v-model="form.risk_level"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>

          <DxButton
            v-if="potentialRisk.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading" class="custom-form">
          <DxButton
            v-if="!paramId && potentialRisk.length < 10"
            class="customBtn"
            text="Form"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            class="customBtn"
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else class="custom-form" />
      </form>
    </div>
  </div>
</template>
            
  <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      potentialRisk: [
        {
          pic_id: "",
          potential: "",
          risk_level: "",
        },
      ],
      readOnly: false,
      riskLevelOptions: [{ name: "Low" }, { name: "Medium" }, { name: "High" }],
      picOption: {},
      paramId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("potentialRisk", [
      "getPotentialRisk",
      "getPotentialRiskStatus",
    ]),
    ...mapGetters("picChecklist", ["getPicChecklist"]),
    ...mapGetters("department", ["getAllDepartment"]),
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      this.readOnly = true;
      if (this.getPotentialRisk) {
        this.potentialRisk[0] = this.getPotentialRisk;
      } else {
        this.loadPic(this.paramId);
      }
    }
    this.getPic();
  },
  emits: ["get-potential-risk"],
  methods: {
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "-";
      }
      return `${depart.company_name} | ${depart.department_name}`;
    },
    async loadPic(params) {
      try {
        const getPic = await this.$store.dispatch(
          "potentialRisk/getPotentialRisk",
          `/${params}`
        );

        this.potentialRisk[0] = getPic.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.potentialRisk.push({
        pic_id: "",
        potential: "",
        risk_level: "",
      });
    },
    removeForm(index) {
      this.potentialRisk.splice(index, 1);
    },
    async getPic() {
      const text = `?limit=999999`;
      const getPic = await this.$store.dispatch(
        "picChecklist/getPicChecklist",
        text
      );
      this.picOption = getPic.data;
    },
    async handleSubmit(e) {
      this.loading = true;
      this.$emit("get-potential-risk", this.potentialRisk);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
            
            <style scoped>
.customBtn {
  margin: 1%;
}
.custom-form {
  margin-top: 10px;
}
.dx-list-item-content {
  white-space: normal;
}
</style>