<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addTemplateDoc" @submit.prevent="handleSubmit">
        <DxTextBox
          class="custom-form"
          label="Nama"
          v-model:value="templateDoc.name"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
        <div>
          <div class="label-text">Lampiran</div>
          <div class="fileuploader-container custom-form">
            <DxFileUploader
              select-button-text="Pilih Dokumen"
              label-text="upload file lampiran *"
              :allowed-file-extensions="fileAllowed"
              :max-file-size="6000000"
              upload-mode="useForm"
              @value-changed="getFile"
              :validationError="validationError"
            >
            </DxFileUploader>
          </div>
          <div v-if="validationError" style="color: red">File is required.</div>
          <div v-if="paramId && !file">
            <!-- ERROR EDIT -->
            File Lama : {{ templateDoc.document_name }}
          </div>
          <div style="text-align: right; margin-bottom: 10px">
            <span>{{ fileAllowed }}</span> - <span>Max : 5 MB</span>.
          </div>
        </div>
        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
          
          <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      unitBisnisOptions: [],
      departmentOptions: [],
      templateDoc: {},
      file: "",
      paramId: this.$route.params.id,
      readOnly: false,
      fileAllowed: [".docx", ".doc", ".pdf", ".xlsx"],
      validationError: false,
    };
  },
  computed: {
    ...mapGetters("templateDocs", ["getTemplateDoc"]),
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      if (this.getTemplateDoc) {
        this.templateDoc = this.getTemplateDoc;
      } else {
        this.loadTemplateDoc(this.paramId);
      }
      this.checkNcSourceOther;
      this.disableAuditee = false;
    }
  },
  emits: ["get-template-doc"],
  methods: {
    async loadTemplateDoc(params) {
      try {
        const getTemplateDoc = await this.$store.dispatch(
          "templateDocs/getTemplateDocs",
          `/${params}`
        );

        this.templateDoc = getTemplateDoc.data;
        console.log(this.templateDoc, "test");
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    getFile(params) {
      if (params.component._files.length > 0) {
        this.loading = !params.component._files[0].isValidFileExtension;
        this.validationError = !params.component._files[0].isValidFileExtension;
        this.file = params.value[0];
      }
    },
    async handleSubmit(e) {
      this.loading = true;
      if (!this.paramId) {
        if (!this.file) {
          this.validationError = true; // Set validation error if no files
          return;
        }
      }
      if (this.file) {
        this.templateDoc.document = this.file;
      }

      this.$emit("get-template-doc", this.templateDoc);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    FilterTableUnitBisnis,
    DxFileUploader,
    DxLoadIndicator,
  },
};
</script>
          
          <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 0;
}
</style>